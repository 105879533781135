import judgementCSS from './judgement.module.css'
import Editbar from '../editbar'
import Askforsave from '../askforsave'
import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Riskjudgement from './components/riskjudgement'
import _ from 'lodash'
import {Apiurl} from '../../../components/constants/constants'
import {useNavigate } from 'react-router-dom'

export default function Judgement(){
    const riskRef = useRef(false)
    const verdRef = useRef(false)
    const [LoadData, setLoadData] = useState(false)
    const [Verdicts, setVerdicts] = useState({})
    const [PreviousData, setPreviousData] = useState({})
    const [Titleslist, setTitleslist] = useState([])
    const [CheckList, setCheckList] = useState([])
    const [DataCheck, setDataCheck] = useState(false)
    const [SelectedVerdict, setSelectedVerdict] = useState()
    const [SelectedVerdictTitle, setSelectedVerdictTitle] = useState("Keine Gefährdung ausgewählt!")
    const [SelectedVerdictId, setSelectedVerdictId] = useState()
    const [RiskVerd, setRiskVerd] = useState()
    const [RiskId, setRiskId] = useState()
    const [dataStatus, setdataStatus] = useState(false)
    const [checkData, setcheckData] = useState(false)
    const [siteswap, setsiteswap] = useState(false)
    const [siteswaprequest, setsiteswaprequest] = useState("")
    const navigate = useNavigate()

    const token = localStorage.getItem('token')

    function wait(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }
    
    const updateRisk = (value) => {
        console.log('Value: ', value[0], 'probability: ', value[1], 'Damage: ', value[2])
        const NewVerdicts = {...Verdicts}
        NewVerdicts[SelectedVerdict].verd[RiskVerd][RiskId].val = value[0]
        NewVerdicts[SelectedVerdict].verd[RiskVerd][RiskId].Eintrittswahrscheinlichkeit = value[1]
        NewVerdicts[SelectedVerdict].verd[RiskVerd][RiskId].Schadensausmaß = value[2]
        setVerdicts(NewVerdicts)
        riskRef.current.classList.toggle(judgementCSS.judgementselected, false)
        setcheckData(!checkData)
    }

    useEffect(() => {
        console.log("Get Data")
        console.log("Selected Project: ", localStorage.getItem('selectedProject'))
        const fetchData = async() => {
            try{
                await axios({
                    method: 'GET',
                    url: Apiurl + '/loadWholeForm?form_id=' + localStorage.getItem('selectedProject'),
                    headers: {Authorization: `Bearer ${token}`}
                }).then(response => {
                    const VerdictData = Object.keys(response.data.verdicts)
                    const responseData = response.data.verdicts
                    setVerdicts(responseData)
                    setPreviousData(_.cloneDeep(responseData))
                    const data = response.data.form
                    console.log("responsedata",responseData)
                    const Data1 = [data["1"], data["2"], data["3"], data["4"], data["5"], data["6"], data["7"], data["8"], data["9"], data["10"], data["11"]]
                    const Titles = Data1.map(Item => {
                        return Item.form_field_info
                    })
                    setTitleslist(Titles)
                    const Data2 = [
                        data["1.1"], data["1.2"], data["1.3"], data["1.4"], data["1.5"], data["1.6"],
                        data["2.1"], data["2.2"], data["2.3"],
                        data["3.1"], data["3.2"], data["3.3"], data["3.4"], data["3.5"], data["3.6"], data["3.7"],
                        data["4.1"], data["4.2"],
                        data["5.1"], data["5.2"], data["5.3"],
                        data["6.1"], data["6.2"],
                        data["7.1"], data["7.2"], data["7.3"], data["7.4"], data["7.5"], data["7.6"], data["7.7"], data["7.8"],
                        data["8.1"], data["8.2"], data["8.3"], data["8.4"], data["8.5"], data["8.6"],
                        data["9.1"], data["9.2"], data["9.3"], data["9.4"], data["9.5"], data["9.6"],
                        data["10.1"], data["10.2"], data["10.3"], data["10.4"],
                        data["11.1"], data["11.2"], data["11.3"], data["11.4"], data["11.5"], data["11.6"], data["11.7"],
                    ]
                    const NewCheckList = Data2.map(Item => {
                        if (Item.form_value===1){
                            setDataCheck(true)
                            if (VerdictData.includes(Item.form_field)){
                                return {id: Item.id, form_field: Item.form_field, form_field_info: Item.form_field_info, filled: true, clicked: true}
                            }
                            return {id: Item.id, form_field: Item.form_field, form_field_info: Item.form_field_info, filled: false, clicked: true}
                        }
                        return {id: Item.id, form_field: Item.form_field, form_field_info: Item.form_field_info, filled: false, clicked: false}
                    })
                    setCheckList(NewCheckList)
                    console.log("VerdictData:", NewCheckList)
                })
                
            }
            catch(e){
                console.log("error")
            }
        }
        fetchData()
        setLoadData(false)
    }, [LoadData])

    const updateCheckList = () => {
        const NewCheckList = {...CheckList}
        if (Verdicts[SelectedVerdict] !== undefined){
            if ((Verdicts[SelectedVerdict].verd[1]===undefined || Verdicts[SelectedVerdict].verd[1].length===0) && (Verdicts[SelectedVerdict].verd[2]===undefined || Verdicts[SelectedVerdict].verd[2].length===0) && (Verdicts[SelectedVerdict].verd[3]===undefined || Verdicts[SelectedVerdict].verd[3].length===0)){
                for (const key in NewCheckList){
                    if (NewCheckList[key].form_field === SelectedVerdict){
                        NewCheckList[key].filled = false
                    }
                }
            }
            else{
                for (const key in NewCheckList){
                    if (NewCheckList[key].form_field === SelectedVerdict){
                        NewCheckList[key].filled = true
                    }
                }
            }
        }
        else{
            for (const key in NewCheckList){
                if (NewCheckList[key].form_field === SelectedVerdict){
                    NewCheckList[key].filled = false
                }
            }
        }
    }
    const selectVerdict = (e) => {
        saveData()
        console.log("Verdict selected:", e.target.name, e.target.id, " Value:",e.target.value)
        setSelectedVerdictTitle(e.target.name)
        setSelectedVerdictId(e.target.id)
        setSelectedVerdict(e.target.value)
        verdRef.current.classList.toggle(judgementCSS.VerdictCheckboxtoggle2, false)
        // verdRef.current.classList.toggle(judgementCSS.VerdictCheckboxtoggle1, true)
    }

    const openJudgementSelection = () => {
        console.log(CheckList)
        if (DataCheck === true){
            console.log("Judgement opened")
            verdRef.current.classList.toggle(judgementCSS.VerdictCheckboxtoggle1, true)
            verdRef.current.classList.toggle(judgementCSS.VerdictCheckboxtoggle2, true)
        }
        else{
            alert("Keine Gefahren vorhanden!")
        }
    }

    const setrisk = (e) => {
        console.log("RiskId:", e.target.id, "RiskVerd:", e.target.name)
        setRiskId(e.target.id)
        setRiskVerd(e.target.name)
        riskRef.current.classList.toggle(judgementCSS.judgementselected)
        riskRef.current.classList.toggle(judgementCSS.smoothjudgement, true)
    }

    const changeVerdictValue = (value, index, name) => {
        const NewVerdicts = {...Verdicts}
        NewVerdicts[SelectedVerdict].verd[name][index].attribute = value
        setVerdicts(NewVerdicts)
        setcheckData(!checkData)
    }

    const addVerdictValue = (id) => {
        if (SelectedVerdict){
            const NewVerdicts = {...Verdicts}
            console.log("Verdicts:", Verdicts)
            if (NewVerdicts[SelectedVerdict] !== undefined){
            }
            else{
                NewVerdicts[SelectedVerdict] = {info: SelectedVerdictTitle, verd: {}}
            }
            if (id === "1"){
                const NewItem = {attribute: "", form_field: SelectedVerdict, form_field_info: SelectedVerdictTitle, head: id, val: 0, Eintrittswahrscheinlichkeit:0, Schadensausmaß:0}
                if (NewVerdicts[SelectedVerdict].verd[id] !== undefined){
                    NewVerdicts[SelectedVerdict].verd[id].push(NewItem)
                }
                else{
                        NewVerdicts[SelectedVerdict].verd[id] = []
                        NewVerdicts[SelectedVerdict].verd[id].push(NewItem)
                }
                setVerdicts(NewVerdicts)
            }
            else {
                const NewItem = {attribute: "", form_field: SelectedVerdict, form_field_info: SelectedVerdictTitle, head: id, val: null}
                if (NewVerdicts[SelectedVerdict].verd[id] !== undefined){
                    NewVerdicts[SelectedVerdict].verd[id].push(NewItem)
                }
                else{
                    NewVerdicts[SelectedVerdict].verd[id] = []
                    NewVerdicts[SelectedVerdict].verd[id].push(NewItem)
                }
                setVerdicts(NewVerdicts)
            }
            setcheckData(!checkData)
        }
        else{
            alert("Keine Gefährdung ausgewählt.")
        }
    }

    const deleteVerdictValue = (id, area) => {
        console.log("Id:", id, "index:", area)
        const NewVerdicts = {...Verdicts}
        NewVerdicts[SelectedVerdict].verd[area].splice(id, 1)
        setVerdicts(NewVerdicts)
        setcheckData(!checkData)
    }

    const getVerdicts = (data) => {
        if (Verdicts[SelectedVerdict] !== undefined){
            if (data === "judgedangers" && Verdicts[SelectedVerdict].verd[1] !== undefined){
                console.log("AllData",Verdicts)
                console.log("SelectedVerdicts", Verdicts[SelectedVerdict].verd[1])
                return(
                    <div className={judgementCSS.judgecontent}>
                        {Verdicts[SelectedVerdict].verd[1].map((item, index) => (
                            <div className={judgementCSS.judgedangerselement}>
                                <p>{index+1}</p>
                                <input name={1} id={index} className={judgementCSS.judgedangerinput} value={item.attribute} onChange={(e) => changeVerdictValue(e.target.value, index, 1)}></input>
                                <button name={1} id={index} className={judgementCSS.riskbtn} onClick={setrisk}>{item.val}</button>
                                <button id={index} className={judgementCSS.deletebtn} onClick={(e) => deleteVerdictValue(e.target.id, 1)}> X</button>
                            </div>
                        ))}
                    </div>
                )
            }
            else if (data === "judgemeasures" && Verdicts[SelectedVerdict].verd[2] !== undefined){
                return(
                    <div className={judgementCSS.judgecontent}>
                        {Verdicts[SelectedVerdict].verd[2].map((item, index) => (
                            <div className={judgementCSS.judgedangerselement}>
                                <p>{index+1}</p>
                                <input className={judgementCSS.judgedangerinput} value={item.attribute} onChange={(e) => changeVerdictValue(e.target.value, index, 2)} list='recommendations'></input>
                                <datalist id='recommendations'>
                                    <option value="Gefährdungsbeurteilung erstellen"/>
                                    <option value="Betriebsanweisung erstellen"/>
                                    <option value="Unterweisungen durchführen und dokumentieren"/>
                                    <option value="Bedienungsanleitung beachten"/>
                                    <option value="PSA zur Verfügung stellen"/>
                                </datalist>
                                <button id={index} className={judgementCSS.deletebtn} onClick={(e) => deleteVerdictValue(e.target.id, 2)}> X</button>
                            </div>
                        ))}
                    </div>
                )
            }
            else if (data === "judgemeasures2" && Verdicts[SelectedVerdict].verd[3] !== undefined){
                return(
                    <div className={judgementCSS.judgecontent}>
                        {Verdicts[SelectedVerdict].verd[3].map((item, index) => (
                            <div className={judgementCSS.judgedangerselement}>
                                <p>{index+1}</p>
                                <input className={judgementCSS.judgedangerinput} value={item.attribute} onChange={(e) => changeVerdictValue(e.target.value, index, 3)} list='recommendations2'></input>
                                <datalist id='recommendations2'>
                                    <option value="Betriebsanweisung"/>
                                    <option value="Unterweisungen durchführen und dokumentieren"/>
                                    <option value="Prüf- und Wartungsintervalle einhalten"/>
                                    <option value="PSA zur Verfügung stellen"/>
                                </datalist>
                                <button id={index} className={judgementCSS.deletebtn} onClick={(e) => deleteVerdictValue(e.target.id, 3)}> X</button>
                            </div>
                        ))}
                    </div>
                )
            }
        }
    }

    const judgement = () => {
        return(
            <div className={judgementCSS.judgecontent}>
                <div className={judgementCSS.judgetitel}>
                    <p>{SelectedVerdict} {SelectedVerdictTitle}</p>
                </div>
                <button className={judgementCSS.handyjudgetitel} onClick={openJudgementSelection}>
                    <p>{SelectedVerdict} {SelectedVerdictTitle}</p>
                </button>
                <div>
                    <div className={judgementCSS.judgedangers}>
                        <p className={judgementCSS.founddangers}> Festgestellte mögliche Gefährdung</p>
                        <p className={judgementCSS.dangerrisk}> Risikobewertung</p>
                        <button id={1} className={judgementCSS.addbtn} onClick={(e) => addVerdictValue(e.target.id)}> Add</button>
                    </div>
                    {getVerdicts("judgedangers")}
                    <div className={judgementCSS.judgemeasures}>
                        <p> Getroffene Maßnahmen</p>
                        <button id={2} className={judgementCSS.addbtn} onClick={(e) => addVerdictValue(e.target.id)}> Add</button>
                    </div>
                    {getVerdicts("judgemeasures")}
                    <div className={judgementCSS.judgemeasures2}>
                        <p>Weitere Maßnahmen</p>
                        <button id={3} className={judgementCSS.addbtn} onClick={(e) => addVerdictValue(e.target.id)}> Add</button>
                    </div>
                    {getVerdicts("judgemeasures2")}
                </div>
            </div>

        )
    }

    useEffect(() => {
        let showDelete = async (e) => {
            if (!riskRef.current.contains(e.target)){
                riskRef.current.classList.toggle(judgementCSS.judgementselected, false)
            }
        };
        document.addEventListener("mousedown", showDelete)

        return() =>{
            document.removeEventListener("mousedown", showDelete)
        }
    });

    const arraysAreEqual = (A, B) => {
        if (A === undefined && B !== undefined){
            return true
        }
        else if (A === undefined && B === undefined) {
            return false
        }
        else{
            if (A.length !== B.length){
                return false
            }
            for (let i = 0; i < A.length; i++) {
                if (A[i].attribute !== B[i].attribute || A[i].val !== B[i].val) {
                    console.log("A:", A, "B:", B)
                    return false;
                }
            }
            return true;
        }
      }

    useEffect(() => {
        if (SelectedVerdict){
            if (!PreviousData[SelectedVerdict] && Verdicts[SelectedVerdict]){
                setdataStatus(true)
                return
            }
            if (PreviousData[SelectedVerdict].verd[1] || PreviousData[SelectedVerdict].verd[2] || PreviousData[SelectedVerdict].verd[3]){
                if (!arraysAreEqual(PreviousData[SelectedVerdict].verd[1], Verdicts[SelectedVerdict].verd[1]) || !arraysAreEqual(PreviousData[SelectedVerdict].verd[2], Verdicts[SelectedVerdict].verd[2]) || !arraysAreEqual(PreviousData[SelectedVerdict].verd[3], Verdicts[SelectedVerdict].verd[3])){
                    setdataStatus(true)
                }
                else{
                    setdataStatus(false)
                }
            }
        }
    },[checkData])
    
    const saveData = () => {
        if (SelectedVerdict !== undefined && Verdicts[SelectedVerdict] !== undefined){
            console.log("previously selected:", SelectedVerdict)
            const saveVerdict = async () => {
                let saveData1 = []
                let saveData2 = []
                let saveData3 = []
                if (Verdicts[SelectedVerdict].verd[1]){
                    saveData1 = Verdicts[SelectedVerdict].verd[1].map((item, index) => (
                        {"head": item.head,
                        "attribute": item.attribute,
                        "val": item.val,
                        "Eintrittswahrscheinlichkeit": item.Eintrittswahrscheinlichkeit,
                        "Schadensausmaß": item.Schadensausmaß,
                        "id": index
                    }
                    ))
                }
                if (Verdicts[SelectedVerdict].verd[2]){
                    saveData2 = Verdicts[SelectedVerdict].verd[2].map((item, index) => (
                        {"head": item.head,
                        "attribute": item.attribute,
                        "id": index
                    }
                    ))
                }
                if (Verdicts[SelectedVerdict].verd[3]){
                    saveData3 = Verdicts[SelectedVerdict].verd[3].map((item, index) => (
                        {"head": item.head,
                        "attribute": item.attribute,
                        "id": index
                    }
                    ))
                }
                const saveData = [...saveData1, ...saveData2, ...saveData3]
                console.log("saveData:", saveData, SelectedVerdictId)
                try {
                    await axios({
                        method: 'POST',
                        url: (Apiurl + "/postVerdict?form_id=" + SelectedVerdictId),
                        data: saveData,
                        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
                    });
                    setPreviousData(_.cloneDeep(Verdicts))
                    if (siteswap){
                        navigate('/home/companies/projects' + siteswaprequest)
                    }
                }
                catch (error) {
                    console.log(error)
                    alert("Something went wrong!")
                }
            }
            saveVerdict()
            updateCheckList()
            setdataStatus(false)
        }
    }

    const Ref1 = useRef(false)
    const Ref2 = useRef(false)
    const Ref3 = useRef(false)
    const Ref4 = useRef(false)
    const Ref5 = useRef(false)
    const Ref6 = useRef(false)
    const Ref7 = useRef(false)
    const Ref8 = useRef(false)
    const Ref9 = useRef(false)
    const Ref10 = useRef(false)
    const Ref11 = useRef(false)
    const RefList = [Ref1, Ref2, Ref3, Ref4, Ref5, Ref6, Ref7, Ref8, Ref9, Ref10, Ref11]
    const usedRefs = {}

    const mapCheckList = (item, index, start, end) => {
        if (index >= start && index <= end){
            if (item.clicked){
                if (item.filled){
                    return <button key={item.form_field} id={item.id} name={item.form_field_info} value={item.form_field} className={judgementCSS.Checkboxbtntrue} onClick={selectVerdict}><div className={judgementCSS.Number}>{item.form_field}</div> <div className={judgementCSS.title}>{item.form_field_info}</div></button>
                }
                else{
                    return <button key={item.form_field} id={item.id} name={item.form_field_info} value={item.form_field} className={judgementCSS.Checkboxbtnfalse} onClick={selectVerdict}><div className={judgementCSS.Number}>{item.form_field}</div> <div className={judgementCSS.title}>{item.form_field_info}</div></button>
                }
            }
            else{
                return null
            }
        }
        else{
            return null
        }
    }

    const getChecklist = (ChecklistArea, start, end) => {
        const ToCheckList = CheckList.map((item, index) => (mapCheckList(item, index, start, end)))
        console.log("CHECKLIST", ToCheckList)
        const ToCheckArea = ToCheckList.slice(start, end+1)
        console.log(ChecklistArea, ToCheckArea)
        const CheckReturn = ToCheckArea.some(item => item != null)
        if (CheckReturn){
            usedRefs[ChecklistArea-1] = RefList[ChecklistArea - 1]
            return(
                <div ref={RefList[ChecklistArea - 1]} className={judgementCSS.ChecklistBox}>
                    <button name={ChecklistArea - 1} className={judgementCSS.ChecklistBoxButton} onClick={(e) => OpenChecklistArea(e.target.name)}>{ChecklistArea}</button>
                    <div className={judgementCSS.ChecklistRightBox}>
                        <button name="info - name"value="value" className={judgementCSS.Checkboxbtntitle}><div className={judgementCSS.Number}>{ChecklistArea}</div> <div className={judgementCSS.title}>{Titleslist[ChecklistArea-1]}</div></button>
                        {ToCheckList}
                    </div>
                </div>
            )
        }
    }

    const OpenChecklistArea = (selectedRef) => {
        usedRefs[selectedRef].current.classList.toggle(judgementCSS.SelectedCheckboxArea)
    }

    const OpenAllChecklistAreas = async() => {
        for (let i = 0; i <11; i++){
            if (usedRefs[i]){
                console.log("Its there")
                usedRefs[i].current.classList.toggle(judgementCSS.SelectedCheckboxArea, true)
                await wait(0.05)
            }
        }
    }

    const CloseAllChecklistAreas = async() => {
        for (let i = 0; i <11; i++){
            if (usedRefs[i]){
                usedRefs[i].current.classList.toggle(judgementCSS.SelectedCheckboxArea, false)
                await wait(0.05)
            }
        }
    }

    const closeChecklist = () => {
        verdRef.current.classList.toggle(judgementCSS.VerdictCheckboxtoggle2, false)
    }

    return(
        <div className={judgementCSS.all}>
            <div className={judgementCSS.riskjudgementbracket}>
                <div ref={riskRef} className={judgementCSS.riskjudgementselector}>
                    <Riskjudgement updateRisk={updateRisk}/>
                </div>
            </div>
            <Editbar setsiteswap={setsiteswap} selectedHover ="judgement" saveData={saveData} dataStatus={dataStatus} setsiteswaprequest={setsiteswaprequest}/>
            <div>
                <Askforsave className={judgementCSS.Askforsave} siteswap={siteswap} setsiteswap={setsiteswap} saveData={saveData} dataStatus={dataStatus} siteswaprequest={siteswaprequest} setsiteswaprequest={setsiteswaprequest}/>
            </div>
            <div className={judgementCSS.Mainbox}>
                <div className={judgementCSS.Verdictbox}>
                    {judgement()}
                </div>
                <div ref={verdRef} className={judgementCSS.VerdictCheckbox}>
                    <div className={judgementCSS.SetAllVerdictsBox}>
                        <button className={judgementCSS.OpenAllVerdicts} onClick={OpenAllChecklistAreas}>Open All</button>
                        <button className={judgementCSS.CloseAllVerdicts} onClick={CloseAllChecklistAreas}> Close All</button>
                    </div>
                    {getChecklist(1, 0, 5)}
                    {getChecklist(2, 6, 8)}
                    {getChecklist(3, 9, 15)}
                    {getChecklist(4, 16, 17)}
                    {getChecklist(5, 18, 20)}
                    {getChecklist(6, 21, 22)}
                    {getChecklist(7, 23, 30)}
                    {getChecklist(8, 31, 36)}
                    {getChecklist(9, 37, 42)}
                    {getChecklist(10, 43, 46)}
                    {getChecklist(11, 47, 55)}
                    <button className={judgementCSS.btn_back} onClick={closeChecklist}>Back</button>
                </div>
            </div>
        </div>
    )
}