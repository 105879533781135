import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import askforsaveCSS from './askforsave.module.css'

export default function Askforsave(props){
    const askforsaveRef = useRef()
    // Props: saveData, 
    //Functions
    const navigate = useNavigate();
    
    const saveswap = () => {
        props.saveData(props.siteswaprequest)
        //navigate('/home/companies/projects' + props.siteswaprequest)
    }

    const nosaveswap = () => {
        navigate('/home/companies/projects' + props.siteswaprequest)
    }
    const getbackwithoutsaving = () => {
        askforsaveRef.current.classList.toggle(askforsaveCSS.showaskforsave, false)
        props.setsiteswap(false)
        props.setsiteswaprequest("")
    }
    useEffect(() => {
        if (props.dataStatus && props.siteswap){
            askforsaveRef.current.classList.toggle(askforsaveCSS.showaskforsave, true)
        }
        else{
            askforsaveRef.current.classList.toggle(askforsaveCSS.showaskforsave, false)
        }
    })

        return (
            <div ref={askforsaveRef} className={askforsaveCSS.box}>
                <div className={askforsaveCSS.deleteconfirm}>
                    <h1>Änderungen speichern?</h1>
                    <p>Bei Seitenwechsel werden alle ungespeicherten Änderungen verworfen.</p>
                    <div className={askforsaveCSS.btn_box}>
                        <button ref={props.btnRef} className={askforsaveCSS.btn_confirm} onClick={saveswap}>
                            Speichern
                        </button>
                        <button ref={props.btn2Ref} className={askforsaveCSS.btn_cancel} onClick={nosaveswap}>
                            Ohne Speichern fortfahren
                        </button>
                        <button ref={props.btn2Ref} className={askforsaveCSS.btn_cancel} onClick={getbackwithoutsaving}>
                            Zurück
                        </button>
                    </div>
                </div>
            </div>

        )

}