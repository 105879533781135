import generalCSS from './general.module.css'
import Editbar from '../editbar'
import Askforsave from '../askforsave'
import placeholderlogo from '../../../assets/Abbildunghinzufugen.png'
import { useState, useEffect } from 'react'
import axios from 'axios'
import {Apiurl} from '../../../components/constants/constants'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Loadingscreen } from '../../../components'

export default function General(){
    const token = localStorage.getItem('token')
    const [dataStatus, setdataStatus] = useState(false)
    const [loadData, setloadData] = useState(false)
    const [loadAMB, setloadAMB] = useState(false)
    const [multipicture, setMultipicture] = useState([])
    const [multipicturechanged, setmultipicturechanged] = useState(false)
    const [KFL, setKFL] = useState()
    const [AMBList, setAMBList] = useState()
    const [AMB1, setAMB1] = useState(placeholderlogo)
    const [previousAMB1, setpreviousAMB1] = useState(placeholderlogo)
    const [AMB2, setAMB2] = useState(placeholderlogo)
    const [previousAMB2, setpreviousAMB2] = useState(placeholderlogo)
    const [AMB3, setAMB3] = useState(placeholderlogo)
    const [previousAMB3, setpreviousAMB3] = useState(placeholderlogo)
    const [AMB4, setAMB4] = useState(placeholderlogo)
    const [previousAMB4, setpreviousAMB4] = useState(placeholderlogo)
    const [AMB5, setAMB5] = useState(placeholderlogo)
    const [previousAMB5, setpreviousAMB5] = useState(placeholderlogo)
    const [AMB6, setAMB6] = useState(placeholderlogo)
    const [previousAMB6, setpreviousAMB6] = useState(placeholderlogo)
    const [siteswap, setsiteswap] = useState(false)
    const [siteswaprequest, setsiteswaprequest] = useState("")
    const navigate = useNavigate();
    const [deleteAMBList, setDeleteAMBList] = useState([])

    const [generalData, setgeneralData] = useState({
        creator: "",
        creation_date: "0001-01-01",
        issuing_person: "",
        responsible_party: "",
        description: ""
    })

    const[previousData, setpreviousData] = useState({
        creator: "",
        creation_date: "0001-01-01",
        issuing_person: "",
        responsible_party: "",
        description: ""
    })

    function wait(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }

    const [loadStatus, setloadStatus] = useState(false)

    const closeload = async() => {
        await wait(1)
        setloadStatus(false)
    }
    
    const saveData = async () => {
        setloadStatus(true)
        const data= {
            company_id: localStorage.getItem("selectedCompany"),
            project_name: localStorage.getItem("selectedProjectName"),
            creator: generalData.creator,
            issuing_person: generalData.issuing_person,
            responsible_party: generalData.responsible_party,
            description: generalData.description,
            work_equipment_picture: multipicture
        }
        
        const saveGeneralData = new FormData();
        saveGeneralData.append("company_id", localStorage.getItem("selectedCompany"))
        saveGeneralData.append("project_name", localStorage.getItem("selectedProjectName"))
        if (generalData.creator !== null){
            saveGeneralData.append("creator", generalData.creator)
        }
        if (generalData.creation_date !== null){
            saveGeneralData.append("creation_date", generalData.creation_date)
            data['creation_date'] = generalData.creation_date
        }
        else {
            saveGeneralData.append("creation_date", "0001-01-01")
            data['creation_date'] = "0001-01-01"
        }
        if (generalData.issuing_person !== null){
            saveGeneralData.append("issuing_person", generalData.issuing_person)
        }
        if (generalData.responsible_party !== null){
            saveGeneralData.append("responsible_party", generalData.responsible_party)
        }
        if (generalData.description !== null){
            saveGeneralData.append("description", generalData.description)
        }
        // ERROR
        if (multipicturechanged === true){
            multipicture.forEach((file, index) => {
                saveGeneralData.append("work_equipment_picture", file)
                saveGeneralData.append("work_equipment_picture_indices", index)
                console.log("Picture: ", saveGeneralData.get("work_equipment_picture"))
              })
        }
        console.log("Zu speichernde Daten:", saveGeneralData)
        console.log(deleteAMBList.length, parseInt(localStorage.getItem('selectedProject')))
        //------------------------------------
        const saveprogress = async() => {
            try{
                await axios({
                    method: 'POST',
                    url: Apiurl + "/postGeneral",
                    data: saveGeneralData,
                    headers: {Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
                }).then(e => {
                    console.log('Saved Data')
                    setpreviousData(generalData)
                    setMultipicture([])
                    setmultipicturechanged(false)
                    setpreviousAMB1(AMB1)
                    setpreviousAMB2(AMB2)
                    setpreviousAMB3(AMB3)
                    setpreviousAMB4(AMB4)
                    setpreviousAMB5(AMB5)
                    setpreviousAMB6(AMB6)
                    setdataStatus(false)
                    if (siteswap){
                        navigate('/home/companies/projects' + siteswaprequest)
                    }
                    closeload()
                })
            }
            catch(error){
                console.log(error)
                alert("Something went wrong!\n\nTry again!")
                closeload()
            }
        }

        // const deleteAMB = async() => {
        //     console.log("AMBs to delete:", deleteAMBList)
        //     try{
        //         await axios({
        //             method: 'DELETE',
        //             url: Apiurl + '/removeAMBs',
        //             params: {general_id: parseInt(localStorage.getItem('selectedProject'))},
        //             data: deleteAMBList,
        //             headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        //         }).then(e => {
        //             console.log("AMBs deleted")
        //             setDeleteAMBList([])
        //             saveprogress()
        //         })
        //     }
        //     catch(e){
        //         console.log(e)
        //     }
        // }
        // if (deleteAMBList.length !== 0){
        //     deleteAMB()
        // }
        // else{
        //     saveprogress()
        // }
        saveprogress()
    }

    const getFile = (e) =>{
        document.getElementById("upfile" + e.target.name).click();
   }

    const changePicture = (e) =>{
        setmultipicturechanged(true)
        const file = e.target.files[0]
        console.log('Multi', multipicture)
        const newmultipictureData = multipicture
        if (deleteAMBList.includes(parseInt(e.target.name))){
            const valueIndex = deleteAMBList.indexOf(parseInt(e.target.name));
            const updatedDeleteAMBList = [...deleteAMBList];
            updatedDeleteAMBList.splice(valueIndex, 1);
            setDeleteAMBList(updatedDeleteAMBList);
        }
        newmultipictureData[e.target.name] = file
        setMultipicture(newmultipictureData)
        console.log(multipicture)

        if (file) {
            const reader = new FileReader();

            reader.onload = function (ev) {
                switch (e.target.id) {
                    case "upfile0": setAMB1(ev.target.result); break;
                    case "upfile1": setAMB2(ev.target.result); break;
                    case "upfile2": setAMB3(ev.target.result); break;
                    case "upfile3": setAMB4(ev.target.result); break;
                    case "upfile4": setAMB5(ev.target.result); break;
                    case "upfile5": setAMB6(ev.target.result); break;
                }
            };
            reader.readAsDataURL(file)
        }
   }

   const deleteamb = async(e) => {
        console.log("index of picture to delete:", e.target.name)
        console.log("DeleteAMBList is:", deleteAMBList)

        const index = parseInt(e.target.name)
        // if (!deleteAMBList.includes(index)){
        //     setDeleteAMBList(prevDeleteAMBList => [...prevDeleteAMBList, index])
        // }
        // console.log(deleteAMBList)
        // console.log("AMBs to delete:", deleteAMBList)
        try{
            await axios({
                method: 'DELETE',
                url: Apiurl + '/removeAMBs',
                params: {general_id: parseInt(localStorage.getItem('selectedProject'))},
                data: [index],
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            }).then(e => {
                switch(index){
                    case 0: setAMB1(placeholderlogo); break;
                    case 1: setAMB2(placeholderlogo); break;
                    case 2: setAMB3(placeholderlogo); break;
                    case 3: setAMB4(placeholderlogo); break;
                    case 4: setAMB5(placeholderlogo); break;
                    case 5: setAMB6(placeholderlogo); break;
                }
                console.log("AMBs deleted")
                setDeleteAMBList([])
            })
        }
        catch(e){
            console.log(e)
        }
   }

        // const deleteAMB = async() => {
        //     console.log("AMBs to delete:", deleteAMBList)
        //     try{
        //         await axios({
        //             method: 'DELETE',
        //             url: Apiurl + '/removeAMBs',
        //             params: {general_id: parseInt(localStorage.getItem('selectedProject'))},
        //             data: deleteAMBList,
        //             headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        //         }).then(e => {
        //             console.log("AMBs deleted")
        //             setDeleteAMBList([])
        //             saveprogress()
        //         })
        //     }
        //     catch(e){
        //         console.log(e)
        //     }
        // }
        // if (deleteAMBList.length !== 0){
        //     deleteAMB()
        // }
        // else{
        //     saveprogress()
        // }

   useEffect(() => {
    const getGeneral = async() => {
        console.log("Load data for project:", localStorage.getItem('selectedProject'))
        try {
            await axios({
              method: 'GET',
              url: Apiurl + '/loadForm?id=' + localStorage.getItem('selectedProject'),
              headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
            }).then(response => {
                setgeneralData({
                    creator: response.data.general.creator,
                    creation_date: response.data.general.creation_date,
                    issuing_person: response.data.general.issuing_person,
                    responsible_party: response.data.general.responsible_party,
                    description: response.data.general.description
                })
                setpreviousData({
                    creator: response.data.general.creator,
                    creation_date: response.data.general.creation_date,
                    issuing_person: response.data.general.issuing_person,
                    responsible_party: response.data.general.responsible_party,
                    description: response.data.general.description
                })
                console.log("Loaded Data:", response.data.general)
            })
        }
        catch (error) {
        }
        try{
            await axios({
                method: 'GET',
                url: Apiurl + '/getKFL?company_id=' + localStorage.getItem('selectedCompany'),
                headers: {Authorization: `Bearer ${token}`}
            }).then(response => {
                console.log(response)
            })
        }
        catch(e){}
        try{
            await axios({
                method: 'GET',
                url: Apiurl + '/getAMBList?form_id=' + localStorage.getItem('selectedProject'),
                headers: {Authorization: `Bearer ${token}`},
            }).then(response => {
                setAMBList(response.data)
                console.log("AMB Liste", response.data)
                setloadAMB(!loadAMB)
            })
        }
        catch(e){}
    }
    const getKFL = async(companyid) => {
        try{
            await axios({
                method: 'GET',
                url: Apiurl + '/getKFL?company_id=' + companyid,
                headers: {Authorization: `Bearer ${token}`},
                responseType: 'blob'
            }).then((response) => {
                const url = URL.createObjectURL(response.data)
                setKFL(url)
            })
        }
        catch (e){
            console.log(e)
        }
    }

    getGeneral();
    getKFL(localStorage.getItem('selectedCompany'))
    setloadData(false)


   }, [loadData])

   useEffect(() => {
    const getAMB = async(img_name, index) => {
        console.log("requested key:", index, "requested picture:", img_name)
        try{
            await axios({
                method: 'GET',
                url: Apiurl + '/getAMB?img_name=' + img_name,
                headers: {Authorization: `Bearer ${token}`},
                responseType: 'blob'
            }).then((response) => {
                const url = URL.createObjectURL(response.data)
                console.log("requested blobpicture:", url)
                if (index === 0){
                    setAMB1(url)
                    setpreviousAMB1(url)
                }
                else if (index === 1){
                    setAMB2(url)
                    setpreviousAMB2(url)
                }
                else if (index === 2){
                    setAMB3(url)
                    setpreviousAMB3(url)
                }
                else if (index === 3){
                    setAMB4(url)
                    setpreviousAMB4(url)
                }
                else if (index === 4){
                    setAMB5(url)
                    setpreviousAMB5(url)
                }
                else if (index === 5){
                    setAMB6(url)
                    setpreviousAMB6(url)
                }
                return
            })
        }
        catch(e){
            alert("Something went wrong!")
        }
    }

    const mapAMBList = () => {
        console.log("AMB Mapping started")
        console.log("This is AMB Mapping Data:", AMBList)
        for (const key in AMBList) {
            getAMB(AMBList[key], parseInt(key))
        }
    }
    if (AMBList !==undefined){
        mapAMBList()
    }
   }, [loadAMB])

   useEffect(() => {
    if (generalData.creator===previousData.creator && generalData.creation_date===previousData.creation_date && generalData.issuing_person===previousData.issuing_person && generalData.responsible_party===previousData.responsible_party && generalData.description===previousData.description && AMB1===previousAMB1 && AMB2===previousAMB2 && AMB3===previousAMB3 && AMB4===previousAMB4 && AMB5===previousAMB5 && AMB6===previousAMB6){
        setdataStatus(false)
    }
    else{
        setdataStatus(true)
    }
   }, [generalData.creator, generalData.creation_date, generalData.issuing_person, generalData.responsible_party, generalData.description, previousData.creator, previousData.creation_date, previousData.issuing_person, previousData.responsible_party, previousData.description, AMB1, previousAMB1, AMB2, previousAMB2, AMB3, previousAMB3, AMB4, previousAMB4, AMB5, previousAMB5, AMB6, previousAMB6])

//    const getAMB2 = () => {
//     console.log("getAMB2  triggered")
//     if (AMBList !== undefined){
//         for (const key in AMBList) {
//             console.log('AMB FOUND', key)
//             return(
//                 AMB(AMBList[key], parseInt(key), deleteamb, changePicture, getFile)
//             )
//         }
//         // return(
//         //     <div className={generalCSS.general_working_equipment}>
//         //         {AMBList.map((image, index) => {
//         //             AMB(image, index, deleteamb, changePicture, getFile)
//         //         })}
//         //     </div>
//         // )
//     }
//    }

    return(
        <div className={generalCSS.mainbox}> 
            <Loadingscreen loadStatus={loadStatus}/>
            <Editbar setsiteswap={setsiteswap} selectedHover ="general" saveData={saveData} dataStatus={dataStatus} setsiteswaprequest={setsiteswaprequest}/>
            <div className={generalCSS.askforsave}>
                <Askforsave siteswap={siteswap} setsiteswap={setsiteswap} saveData={saveData} dataStatus={dataStatus} siteswaprequest={siteswaprequest} setsiteswaprequest={setsiteswaprequest}/>
            </div>
            <div className={generalCSS.subbox}>
            <div className={generalCSS.general_mainbox}>
                <div className={generalCSS.general_info}>
                    <img className={generalCSS.general_logoimg} src={KFL} alt='Kein Bild verfügbar'></img>
                    <div className={generalCSS.info_form}>
                        <p>Ersteller:</p>
                        <input name='creator' value={generalData.creator} className={generalCSS.info_forminput} type='text' onChange={(e) => setgeneralData({...generalData, [e.target.name]: e.target.value})}></input>
                    </div>
                    <div className={generalCSS.info_form}>
                        <p>Erstellungsdatum:</p>
                        <input name='creation_date' value={generalData.creation_date} className={generalCSS.info_forminput} type='date' onChange={(e) => setgeneralData({...generalData, [e.target.name]: e.target.value})}></input>
                    </div>
                    <div className={generalCSS.info_form}>
                        <p>Erlassende Person:</p>
                        <input name='issuing_person' value={generalData.issuing_person} className={generalCSS.info_forminput} type='text' onChange={(e) => setgeneralData({...generalData, [e.target.name]: e.target.value})}></input>
                    </div>  
                    <div className={generalCSS.info_form}>  
                        <p>Verantwortliche/r:</p>
                        <input name='responsible_party' value={generalData.responsible_party} className={generalCSS.info_forminput} type='text' onChange={(e) => setgeneralData({...generalData, [e.target.name]: e.target.value})}></input>
                    </div>
                </div>
                <div className={generalCSS.general_working_equipment}>
                    <div className={generalCSS.equiptmentinputbox}>
                        <button name={0} className={generalCSS.equiptmentinputbtn} onClick={getFile}>
                            <img className={generalCSS.equiptmentinputimg} src={AMB1} alt='abc'></img>
                        </button>
                        <button name={0} className={generalCSS.equiptmentinputdelete} onClick={deleteamb}></button>
                        <p className={generalCSS.equiptmentinputp} >Abbildung 1</p>
                        <input name={0} id="upfile0" className={generalCSS.equiptmentinput} type='file' onChange={changePicture} accept="image/png, image/jpeg"></input>
                    </div>
                    <div className={generalCSS.equiptmentinputbox}>
                        <button name={1} className={generalCSS.equiptmentinputbtn} onClick={getFile}>
                            <img className={generalCSS.equiptmentinputimg} src={AMB2} alt='abc'></img>
                        </button>
                        <button name={1} className={generalCSS.equiptmentinputdelete} onClick={deleteamb}></button>
                        <p className={generalCSS.equiptmentinputp} >Abbildung 2</p>
                        <input name={1} id="upfile1" className={generalCSS.equiptmentinput} type='file' onChange={changePicture} accept="image/png, image/jpeg"></input>
                    </div>
                    <div className={generalCSS.equiptmentinputbox}>
                        <button name={2} className={generalCSS.equiptmentinputbtn} onClick={getFile}>
                            <img className={generalCSS.equiptmentinputimg} src={AMB3} alt='abc'></img>
                        </button>
                        <button name={2} className={generalCSS.equiptmentinputdelete} onClick={deleteamb}></button>
                        <p className={generalCSS.equiptmentinputp} >Abbildung 3</p>
                        <input name={2} id="upfile2" className={generalCSS.equiptmentinput} type='file' onChange={changePicture} accept="image/png, image/jpeg"></input>
                    </div>
                    <div className={generalCSS.equiptmentinputbox}>
                        <button name={3} className={generalCSS.equiptmentinputbtn} onClick={getFile}>
                            <img className={generalCSS.equiptmentinputimg} src={AMB4} alt='abc'></img>
                        </button>
                        <button name={3} className={generalCSS.equiptmentinputdelete} onClick={deleteamb}></button>
                        <p className={generalCSS.equiptmentinputp}>Abbildung 4</p>
                        <input name={3} id="upfile3" className={generalCSS.equiptmentinput} type='file' onChange={changePicture} accept="image/png, image/jpeg"></input>
                    </div>
                    <div className={generalCSS.equiptmentinputbox}>
                        <button name={4} className={generalCSS.equiptmentinputbtn} onClick={getFile}>
                            <img className={generalCSS.equiptmentinputimg} src={AMB5} alt='abc'></img>
                        </button>
                        <button name={4} className={generalCSS.equiptmentinputdelete} onClick={deleteamb}></button>
                        <p className={generalCSS.equiptmentinputp}>Abbildung 5</p>
                        <input name={4} id="upfile4" className={generalCSS.equiptmentinput} type='file' onChange={changePicture} accept="image/png, image/jpeg"></input>
                    </div>
                    <div className={generalCSS.equiptmentinputbox}>
                        <button name={5} className={generalCSS.equiptmentinputbtn} onClick={getFile}>
                            <img className={generalCSS.equiptmentinputimg} src={AMB6} alt='abc'></img>
                        </button>
                        <button name={5} className={generalCSS.equiptmentinputdelete} onClick={deleteamb}></button>
                        <p className={generalCSS.equiptmentinputp}>Abbildung 6</p>
                        <input name={5} id="upfile5" className={generalCSS.equiptmentinput} type='file' onChange={changePicture} accept="image/png, image/jpeg"></input>
                    </div>
                </div>
                {/* {getAMB2()} */}
            </div>
            <div className={generalCSS.descriptionbox}>
                <textarea name='description' value={generalData.description} placeholder='Beschreibung' className={generalCSS.description} onChange={(e) => setgeneralData({...generalData, [e.target.name]: e.target.value})}></textarea>
            </div>
            {/* <button  onClick={testfunction}>asdasd</button> */}
            </div>
        </div>

    )
}