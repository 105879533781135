import projectselectionCSS from './projectselection.module.css'
import {Navbar} from "../../components"
import {useState, useEffect, useRef} from "react"
import {useNavigate} from 'react-router-dom'
import placeholderlogo from '../../assets/Gefaehrdunghinzufugen2.png'
import {default as Deleteproject} from './deleteproject.jsx'
import {default as ChangeProjectName} from './changeProjectName.jsx'
import axios from 'axios'
import {Apiurl} from '../../components/constants/constants'
import {FaPencilAlt} from "react-icons/fa";

export default function Projects(){
    //Variables
    const [projects, setProjects] = useState([])
    const [ProjectNames, setProjectNames] = useState([])
    const [LoadAllProjects, setLoadAllProjects] = useState(false)
    const [addProjectName, setaddProjectName] = useState("")
    const [selectedProject, setselectedProject] = useState([])
    const [selectedProjectId, setselectedProjectId] = useState([])
    const [KFL, setKFL] = useState()
    const token = localStorage.getItem('token')

    //DeleteProject
    const requiredpin = '3264'
    const [pin, setpin] = useState("")

    //Refs
    const addRef = useRef();
    const blurRef = useRef();
    const dRef = useRef();
    const cRef = useRef();
    const btnRef = useRef();
    const btn2Ref = useRef();
    const btnRef2 = useRef();
    const btn2Ref2 = useRef();
    const inpRef = useRef();
    const inpRef2 = useRef();

    //Functions
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
        );

    const navigate = useNavigate();

    useEffect(() => {
        const getKFL = async() => {
            try{
                await axios({
                    method: 'GET',
                    url: Apiurl + '/getKFL?company_id=' + localStorage.getItem('selectedCompany'),
                    headers: {Authorization: `Bearer ${token}`},
                    responseType: 'blob'
                })
                .then((response) => {
                    setKFL(URL.createObjectURL(response.data))
                })
            }
            catch(e){
                console.log("Kein Logo gefunden.")
            }
        }
        const fetchProject = async () => {
            try{
                await axios({
                    method: 'GET',
                    url: Apiurl + '/loadProjects?company_id=' + localStorage.getItem('selectedCompany'),
                    headers: {Authorization: `Bearer ${token}`}
                }).then(response => {
                    setProjects(response.data);
                    setProjectNames(response.data.map(project => project.project_name))
                    setLoadAllProjects(false)
                    //console.log(response.data.map(project => project.project_name))
                })
            }
            catch(e){
                alert(e.response.data.detail)
            }
        };
        console.log('Company ID:', localStorage.getItem('selectedCompany'))
        getKFL()
        fetchProject()
        console.log('Fetched Projects')
    }, [LoadAllProjects]);

    //Select Project
    const selectProject = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)
        localStorage.setItem('selectedProject', e.target.value)
        localStorage.setItem('selectedProjectName', e.target.name)
        navigate('/home/companies/projects/edit/general')
    }
    const deleteProject = (e) => {
        setselectedProject(e.target.name)
        setselectedProjectId(e.target.value)
        blurRef.current.classList.toggle(projectselectionCSS.responsive_blur, true)
        dRef.current.classList.toggle(projectselectionCSS.responsive_deleterequest, true)
        dRef.current.classList.toggle(projectselectionCSS.responsive_transition, true)
    }

    const changeProjektName = (e) => {
        setselectedProject(e.target.name)
        setselectedProjectId(e.target.value)
        console.log(selectedProjectId, e.target.value)
        blurRef.current.classList.toggle(projectselectionCSS.responsive_blur, true)
        cRef.current.classList.toggle(projectselectionCSS.responsive_deleterequest, true)
        cRef.current.classList.toggle(projectselectionCSS.responsive_transition, true)
    }

    const addProject = async() => {
        if (addProjectName === "") {
            console.log('Kein Projektname eingetragen.')
        }
        else if (ProjectNames.includes(addProjectName) === true){
            console.log('Projektname bereits vergeben.')
        }
        else{
            const createProjectData = new FormData();
            createProjectData.append("company_id", localStorage.getItem('selectedCompany'))
            createProjectData.append("project_name", addProjectName)
            try {
                const token = localStorage.getItem('token')
                await axios({
                  method: 'POST',
                  url: Apiurl + "/postGeneral",
                  data: createProjectData,
                  headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
                }).then(e => {
                    setaddProjectName("")
                    setLoadAllProjects(!LoadAllProjects)
                })
              }
              catch (error) {
              }
         }
    }

    useEffect(() => {
        //closeDelete
        let showDelete = async (e) => {
            if ((dRef.current.contains(e.target) || blurRef.current.contains(e.target)) && !btnRef.current.contains(e.target) && !inpRef.current.contains(e.target)){
                setpin("")
                dRef.current.classList.toggle(projectselectionCSS.responsive_deleterequest, false)
                cRef.current.classList.toggle(projectselectionCSS.responsive_deleterequest, false)
                blurRef.current.classList.toggle(projectselectionCSS.responsive_blur, false)
                await delay(1000)
                dRef.current.classList.toggle(projectselectionCSS.responsive_transition, false)

            }
            else if ((cRef.current.contains(e.target) || blurRef.current.contains(e.target)) && !btnRef2.current.contains(e.target) && !inpRef2.current.contains(e.target)){
                cRef.current.classList.toggle(projectselectionCSS.responsive_deleterequest, false)
                blurRef.current.classList.toggle(projectselectionCSS.responsive_blur, false)
                await delay(1000)
                cRef.current.classList.toggle(projectselectionCSS.responsive_transition, false)
            }
        };
        document.addEventListener("mousedown", showDelete)

        return() =>{
            document.removeEventListener("mousedown", showDelete)
        }
    });

    useEffect(() => {
        if (addProjectName === "" || ProjectNames.includes(addProjectName) === true){
            addRef.current.classList.toggle(projectselectionCSS.addable, false)
        }
        else {
            addRef.current.classList.toggle(projectselectionCSS.addable, true)
        }
    });

    useEffect(() => {
        const spacer = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter'){
                console.log('Enter key was pressed.')
                addProject()
            }
        }
        document.addEventListener('keydown', spacer);
        return () => {
            document.removeEventListener('keydown', spacer)
        }
    });

    return (
        <div>
            <div className = {projectselectionCSS.deletebracket}>
                <div ref={blurRef} className={projectselectionCSS.blurbox}></div>
                <div ref={dRef} className={projectselectionCSS.deletebox}>
                    <Deleteproject selectedProject={selectedProject} setselectedProject={setselectedProject} btnRef={btnRef} btn2Ref={btn2Ref} inpRef={inpRef} requiredpin={requiredpin} pin={pin} setpin={setpin} LoadAllProjects={LoadAllProjects} setLoadAllProjects={setLoadAllProjects} selectedProjectId={selectedProjectId} setselectedProjectId={setselectedProjectId} delay={delay} projectselectionCSS={projectselectionCSS} dRef={dRef} blurRef={blurRef}/>
                </div>
                <div ref={cRef} className={projectselectionCSS.deletebox}>
                    <ChangeProjectName selectedProject={selectedProject} setselectedProject={setselectedProject} btnRef2={btnRef2} btn2Ref2={btn2Ref2} inpRef2={inpRef2} LoadAllProjects={LoadAllProjects} setLoadAllProjects={setLoadAllProjects} selectedProjectId={selectedProjectId} delay={delay} projectselectionCSS={projectselectionCSS} cRef={cRef} blurRef={blurRef} projects={projects} setProjects={setProjects}/>
                </div>
            </div>
            <Navbar currentpage="Projektauswahl"/>
            <div className={projectselectionCSS.projectlist}>
                {projects.map(project => {
                    if (project.project_name.length > 14){
                        return(
                        <div key={project.id} className={projectselectionCSS.buttonbox}>
                            <button value={project.id} name={project.project_name} className={projectselectionCSS.changeNamebtn} onClick={changeProjektName}><FaPencilAlt className={projectselectionCSS.pencil}/></button>
                            <button value={project.id} name={project.project_name} className={projectselectionCSS.X} onClick={deleteProject}>x</button>
                            <button name={project.project_name} className={projectselectionCSS.projectbtn} value={project.id} onClick={selectProject}>
                                <img src={KFL} alt='---'></img>
                                <p className={projectselectionCSS.runningtext}>{project.project_name}</p>
                            </button>
                        </div>
                        )
                    }else{
                        return(
                        <div key={project.id} className={projectselectionCSS.buttonbox}>
                            <button value={project.id} name={project.project_name} className={projectselectionCSS.changeNamebtn} onClick={changeProjektName}><FaPencilAlt className={projectselectionCSS.pencil}/></button>
                            <button value={project.id} name={project.project_name} className={projectselectionCSS.X} onClick={deleteProject}>x</button>
                            <button name={project.project_name} className={projectselectionCSS.projectbtn} value={project.id} onClick={selectProject}>
                                <img src={KFL} alt='---'></img>
                                <p>{project.project_name}</p>
                            </button>
                        </div>
                        )
                    }
                })}
                <div className={projectselectionCSS.Addbtn}>
                    <button ref={addRef} className={projectselectionCSS.projectbtn2} onClick={addProject}>
                        <img src={placeholderlogo} alt='placeholder'></img>
                    </button>
                <input className={projectselectionCSS.add_input} value={addProjectName} placeholder='Hinzufügen' onChange={(e) => setaddProjectName(e.target.value)}/>
            </div>
            </div>
        </div>
    )
}
