import React from 'react'
import logo from '../../../src/assets/Logo_mit_Standorte.png'
import LoginCSS from './login.module.css'
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import { useState, useRef} from 'react';
import {Apiurl, Version} from '../../components/constants/constants'
import { Impressum, Datenschutz } from '../../components';

export default function Login(){

  // const [FirstLoad, setFirstLoad] = useState(false)
  const navigate = useNavigate();
    //delay function for navbar OUTSOURCE
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  const [mail, setMail] = useState("")
  const [password, setPassword] = useState("")
  const [impressum, setimpressum] = useState(false)
  const [datenschutz, setdatenschutz] = useState(false)
  // const [cookies, setcookies] = useState(true)

  const LogRef = useRef()

  const login = async () => {
    const loginFormData = new FormData();
    loginFormData.append("username", mail)
    loginFormData.append("password", password)

    try{
      await axios({
        method: "post",
        url: Apiurl + "/token",
        data: loginFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(response => {
        localStorage.setItem('token', response.data.access_token)
        loginanimation()
      })
    }
    catch(error) {
      if (error.response) {
        alert("Logindaten nicht korrekt!");
      } else if (error.request) {
        alert(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        alert('Error', error.message);
      }
    }
  };

  const loginanimation = async() => {
    LogRef.current.classList.toggle(LoginCSS.responsive_form, true)
    await delay(1000)
    navigate('/home/companies')
  }
    // const register = (e) => {
    //   navigate(e.target.name)
    // }

  // useEffect(() => {
  //   // console.log("this is apiurl", Apiurl)
  //   localStorage.setItem('cookies', null)
  //   console.log(localStorage.getItem('cookies'))
  //   if (localStorage.getItem('cookies') === 'null' || localStorage.getItem('cookies') === null){
  //     setcookies(true)
  //   } else{
  //     setcookies(false)
  //   }
  // }, [])

  return (
    <body>
      <section className={LoginCSS.Container}>
        {/* <Cookies setcookies={setcookies} cookies={cookies}/> */}
        <Datenschutz setdatenschutz={setdatenschutz} datenschutz={datenschutz} setimpressum={setimpressum}/>
        <Impressum setimpressum={setimpressum} impressum={impressum} setdatenschutz={setdatenschutz}/>
        <div ref={LogRef} className={LoginCSS.form}>
          <div>
            <form action="">
              <div className={LoginCSS.logob}>
                <img className={LoginCSS.logo} src={logo} alt="Logo_mit_Standorte.png" />
              </div>
              <h2 className={LoginCSS.title}>Login</h2>
              <div className={LoginCSS.input}>
                <input value={mail} type="text" required onChange={(e) => setMail(e.target.value)}></input>
                <label for="">Benutzername</label>
              </div>
              <div className={LoginCSS.input}>
                <input value={password} type="password" required onChange={(e) => setPassword(e.target.value)}></input>
                <label for="">Passwort</label>
              </div>
            </form>
            <button name='/home' className={LoginCSS.btn} onClick={login}>Einloggen</button>
          </div>
        </div>
        <div className={LoginCSS.subbox}>
          <button className={LoginCSS.impressum} onClick={() => setimpressum(true)}>Impressum</button>
          <button className={LoginCSS.impressum} onClick={() => setdatenschutz(true)}>Datenschutz</button>
          {/* <button className={LoginCSS.impressum} onClick={() => setcookies(true)}>Cookies</button> */}
        </div>
        <p className={LoginCSS.version}>{Version}</p>
      </section>
    </body>
  )
}