import axios from 'axios'
import deleteprojectCSS from './deleteproject.module.css'
import {Apiurl} from '../../components/constants/constants'

export default function Deleteproject(props){
    //Functions
    const confirmDeleteProject = async() => {
        if (props.pin === props.requiredpin){
            console.log('Confirm')
            try {
                const token = localStorage.getItem('token')
                await axios({
                    method: 'DELETE',
                    url: Apiurl + '/deleteForm?form_id=' + props.selectedProjectId,
                    headers: {Authorization: `Bearer ${token}`}
                }).then(response => {
                    props.setLoadAllProjects(!props.LoadAllProjects)
                    props.setselectedProjectId("")
                    props.dRef.current.classList.toggle(props.projectselectionCSS.responsive_deleterequest, false)
                    props.blurRef.current.classList.toggle(props.projectselectionCSS.responsive_blur, false)
                    props.delay(1000)
                    props.dRef.current.classList.toggle(props.projectselectionCSS.responsive_transition, false)
                    props.setpin("")
                })
            }
            catch(e){
                alert(e.response.data.detail)
                // navigate('/')
            }
        }
        else {
            alert("PIN nicht korrekt.")
            console.log('declined')
        }
    }

    const cancelDeleteProject = () => {
        console.log('Delete canceled')
    }

        return (
            <div className={deleteprojectCSS.box}>
                <div className={deleteprojectCSS.deleteconfirm}>
                    <h1>{props.selectedProject} löschen!</h1>
                    <p>Alle Daten dieser Firma werden unwiderruflich gelöscht!</p>
                    <input ref={props.inpRef} value={props.pin} type="password" name="Ersteller" placeholder='&#9679;&#9679;&#9679;&#9679;' maxLength="4" className={deleteprojectCSS.pinput} onChange={(e) => props.setpin(e.target.value)}/>
                    <div className={deleteprojectCSS.btn_box}>
                        <button ref={props.btnRef} className={deleteprojectCSS.btn_confirm} onClick={confirmDeleteProject}>
                            Bestätigen
                        </button>
                        <button ref={props.btn2Ref} className={deleteprojectCSS.btn_cancel} onClick={cancelDeleteProject}>
                            Abbrechen
                        </button>
                    </div>
                </div>
            </div>

        )

}