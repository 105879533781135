import companyselectionCSS from './companyselection.module.css'
import {useState, useEffect, useRef} from "react"
import placeholderlogo from '../../assets/Firmahinzufuegen.png'
import {Navbar, Loadingscreen} from "../../components"
import {default as Deletecompany} from './deletecompany.jsx'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import React from 'react'
import {Apiurl} from '../../components/constants/constants'

// Icons
import {AiOutlinePicture} from "react-icons/ai"

export default function Companies(){
//Spielplatz

    //Variables
    const token = localStorage.getItem('token')
    const dRef = useRef();
    const btnRef = useRef();
    const btn2Ref = useRef();
    const inpRef = useRef();
    const blurRef = useRef();
    const addRef = useRef();
    
        //DeleteCompany
        const requiredpin = '3264'
        const [pin, setpin] = useState("")

        //AddCompany
        const [addCompanyName, setaddCompanyName] = useState("")
    
    
    //Companies
    const [companies, setCompanies] = useState([]) //Requires picture + Name
    const [CompanyNames, setCompanyNames] = useState([])
    const [LoadAllCompanies, setLoadAllCompanies] = useState(false)
    const [selectedCompany, setselectedCompany] = useState()
    const [selectedCompanyId, setselectedCompanyId] = useState()

    const [ImageURL, setImageURL] = useState([])

    function wait(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }

    const [loadStatus, setloadStatus] = useState(false)

    const closeload = async() => {
        await wait(1)
        setloadStatus(false)
    }

    // const [changepassword, setchangepassword] = useState()
    //Major Functions
    // Fetch Companies
    useEffect(() => {
        console.log("1. Company fetch")
        setCompanies([])
        const fetchCompany = async () => {
            try{
                await axios({
                    method: 'GET',
                    url: Apiurl + '/loadCompanies',
                    headers: {Authorization: `Bearer ${token}`}
            }).then(response => {
                console.log('Data refreshed', response.data)
                const data = response.data
                setCompanyNames(data.map(company => company.name))
                setCompanies(data)
                
                const getKFL = async(companyid) => {
                    try{
                        await axios({
                            method: 'GET',
                            url: Apiurl + '/getKFL?company_id=' + companyid,
                            headers: {Authorization: `Bearer ${token}`},
                            responseType: 'blob'
                        }).then((response) => {
                            const url = URL.createObjectURL(response.data)
                            const index = data.findIndex(item => item.id === companyid)
                            data[index].company_logo = url
                            console.log(data[index], companyid, url)
                            //setImageURL(prevData => [...prevData, {id: companyid, Logo: url}])
                            setImageURL([])
                        })
                    }
                    catch (e){
                        console.log(e)
                    }
                }
                data.map(company => {
                    getKFL(company.id)
                })
                setCompanies(data)
                })
            }
            catch(e){
                //alert(e.response.data.detail)
                //navigate('/')
            }
        }

        //console.log(variable)
        fetchCompany()
        console.log('Fetched Companies')
    }, [LoadAllCompanies]);


    //Functions
    const navigate = useNavigate();

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const changePicture = async(e) =>{
        //Console
        // setloadStatus(false)
        setloadStatus(true)
        console.log('Company ID:', e.target.name)
        //Code
        let id = parseInt(e.target.name)
        const OldData = [...companies]
        const index = OldData.findIndex(item => item.id === id)
        const file = e.target.files[0]

        const saveLogo = new FormData();
        saveLogo.append("img", file)
        try{
            await axios({
                method: 'POST',
                url: Apiurl + '/postCompanyLogo?company_id=' + id,
                data: saveLogo,
                headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}
            }).then(response =>{
                console.log("Logo uploaded")
                console.log("successfully uploaded", response)
                //setLoadAllCompanies(!LoadAllCompanies)
                if (file) {
                    const reader = new FileReader()
                    reader.onload = (ev) => {
                        OldData[index].company_logo = ev.target.result
                        setCompanies(OldData)
                    }
                    reader.readAsDataURL(file)
                }
                closeload()
            })
        }
        catch(e){
            console.log("Failed fileupload")
            closeload()
        }
    }

    const getFile = (e) =>{
        document.getElementById("logochange" + e.target.name).click();
   }

    //Add Company
    const addCompany = async() => {
        console.log("addCompany triggered")
        if (addCompanyName === "") {
          console.log("Der Name ist leer.")
        }
        else if(CompanyNames.includes(addCompanyName) === true){
            console.log('Der Name wird bereits verwendet.')
        }
        else {
            const token = localStorage.getItem('token')
            try{
                await axios({
                    method: 'POST',
                    url: Apiurl + '/createCompany?name=' + addCompanyName,
                    headers: {Authorization: `Bearer ${token}`}
                }).then(response => {
                    // response.json()
                    setaddCompanyName("")
                    setLoadAllCompanies(!LoadAllCompanies)
                })
            }
            catch(e){
                console.log(e.response.data.detail)
                setaddCompanyName('')
                alert('Keine Rechte.')
                // navigate('/')
            }
        }
      }

    //Select Company
    const selectCompany = (e) => {
        localStorage.setItem('selectedCompany', e.target.value)
        localStorage.setItem('selectedCompanyLogo', e.target.logo)
        navigate('/home/companies/projects')
    }

    const deleteCompany = (e) => {
        console.log(e.target.value)
        setselectedCompany(e.target.name)
        setselectedCompanyId(e.target.value)
        blurRef.current.classList.toggle(companyselectionCSS.responsive_blur, true)
        dRef.current.classList.toggle(companyselectionCSS.responsive_deleterequest, true)
        dRef.current.classList.toggle(companyselectionCSS.responsive_transition, true)
    }

    useEffect(() => {
        //closeDelete
        let showDelete = async (e) => {
            if ((dRef.current.contains(e.target) || blurRef.current.contains(e.target)) && !btnRef.current.contains(e.target) && !inpRef.current.contains(e.target)){
                setpin("")
                dRef.current.classList.toggle(companyselectionCSS.responsive_deleterequest, false)
                blurRef.current.classList.toggle(companyselectionCSS.responsive_blur, false)
                await delay(1000)
                dRef.current.classList.toggle(companyselectionCSS.responsive_transition, false)
            }
        };
        document.addEventListener("mousedown", showDelete)

        return() =>{
            document.removeEventListener("mousedown", showDelete)
        }
    });

    useEffect(() => {
        if (addCompanyName === "" || CompanyNames.includes(addCompanyName) === true){
            addRef.current.classList.toggle(companyselectionCSS.addable, false)
        }
        else {
            addRef.current.classList.toggle(companyselectionCSS.addable, true)
        }
    })

    useEffect(() => {
        const spacer = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter'){
                console.log('Enter key was pressed. Run your Function')
                addCompany()
            }
        }
        document.addEventListener('keydown', spacer);
        return () => {
            document.removeEventListener('keydown', spacer)
        }
    });
        return (
            <div>
                <Loadingscreen loadStatus={loadStatus}/>
                <div className = {companyselectionCSS.deletebracket}>
                    <div ref={blurRef} className={companyselectionCSS.blurbox}></div>
                    <div ref={dRef} className={companyselectionCSS.deletebox}>
                        <Deletecompany selectedCompany={selectedCompany} setselectedCompany={setselectedCompany} btnRef={btnRef} btn2Ref={btn2Ref} inpRef={inpRef} requiredpin={requiredpin} pin={pin} setpin={setpin} LoadAllCompanies={LoadAllCompanies} setLoadAllCompanies={setLoadAllCompanies} selectedCompanyId={selectedCompanyId} setselectedCompanyId={setselectedCompanyId} delay={delay} companyselectionCSS={companyselectionCSS} dRef={dRef} blurRef={blurRef}/>
                    </div>
                </div>
                <Navbar currentpage="Firmenauswahl"/>
                <div className={companyselectionCSS.companylist}>
                    {console.log("map", companies)}
                    {companies.map(company => (
                        <div key={company.id} className={companyselectionCSS.buttonbox}>
                            <button value={company.id} name={company.name} className={companyselectionCSS.X} onClick={deleteCompany}>x</button>
                            <button value={company.id} name={company.name} className={companyselectionCSS.logoinputbtn} onClick={getFile}>
                                <AiOutlinePicture className={companyselectionCSS.logoinputimg}/>
                            </button>
                            <input name={company.id} id={"logochange" + company.name} aid={company.id} className={companyselectionCSS.logoinput} type='file' onChange={changePicture} accept="image/png, image/jpeg"/>
                            <button className={companyselectionCSS.companybtn} value={company.id} logo={company.company_logo} onClick={selectCompany}>
                                <img src={company.company_logo} alt='---'></img>
                                <p>{company.name}</p>
                            </button>
                        </div>
                    ))}
                    <div className={companyselectionCSS.Addbtn}>
                        <button ref={addRef} className={companyselectionCSS.companybtn2} onClick={addCompany}>
                            <img src={placeholderlogo} alt='placeholder'></img>
                        </button>
                        <input className={companyselectionCSS.add_input} value={addCompanyName} placeholder='Hinzufügen' onChange={(e) => setaddCompanyName(e.target.value)}/>
                    </div>
                </div>
            </div>
        )

}