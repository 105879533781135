import logo from '../../assets/Logo_mit_Standorte.png'
import editbarCSS from './editbar.module.css'
import {FaBars, FaRegSave} from "react-icons/fa"
import {useRef, useEffect, useState} from "react"
import { useNavigate } from 'react-router-dom';

export default function Editbar(props){
    const [toggleSelected, settoggleSelected] = useState(false)
    const navRef = useRef();
    const homeref = useRef();
    const btnref1 = useRef();
    const btnref2 = useRef();
    const btnref3 = useRef();
    const btnref4 = useRef();
    const btnref5 = useRef();
    const btnref6 = useRef();
    const btnref7 = useRef();
    const Barref = useRef();

    const openMenu = () => {
        console.log("Menu opened")
        navRef.current.classList.toggle(editbarCSS.responsive_nav, true)
        Barref.current.classList.toggle(editbarCSS.Barclicked, true)
    }

    //delay function for navbar OUTSOURCE
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    const navigate = useNavigate();
    
    const changeEdit = (e) => {
        //props.saveData()
        if (props.dataStatus){
            navRef.current.classList.toggle(editbarCSS.responsive_nav, false)
            Barref.current.classList.toggle(editbarCSS.Barclicked, false)
            props.setsiteswap(true)
            props.setsiteswaprequest(e.target.name)
        }
        else{
            navRef.current.classList.toggle(editbarCSS.responsive_nav, false)
            Barref.current.classList.toggle(editbarCSS.Barclicked, false)
            navigate('/home/companies/projects' + e.target.name)
        }
    }

    useEffect(() => {
        //closeNavbar
        let showNavbar = async (e) => {
            if (navRef.current.contains(e.target) && !homeref.current.contains(e.target) && !btnref1.current.contains(e.target) && !btnref2.current.contains(e.target) && !btnref3.current.contains(e.target) && !btnref4.current.contains(e.target) && !btnref5.current.contains(e.target) && !btnref6.current.contains(e.target)){
                navRef.current.classList.toggle(editbarCSS.responsive_nav, false)
                Barref.current.classList.toggle(editbarCSS.Barclicked, false)
                // btnRef.current.classList.toggle(editbarCSS.responsive_btn, false)
                await delay(1000);
                // navRef.current.classList.toggle(editbarCSS.secondnav, false)
            }
        };
        document.addEventListener("mousedown", showNavbar)

        return() =>{
            document.removeEventListener("mousedown", showNavbar)
        }
    });

    useEffect(() => {
        if (props.selectedHover === "general"){
            btnref1.current.classList.toggle(editbarCSS.selectedButton, true)
        }
        else if (props.selectedHover === "clicker"){
            btnref2.current.classList.toggle(editbarCSS.selectedButton, true)
        }
        else if (props.selectedHover === "judgement"){
            btnref3.current.classList.toggle(editbarCSS.selectedButton, true)
        }
        else if (props.selectedHover === "summary"){
            btnref4.current.classList.toggle(editbarCSS.selectedButton, true)
        }
        else if (props.selectedHover === "pdf"){
            btnref5.current.classList.toggle(editbarCSS.selectedButton, true)
        }
        settoggleSelected(false)
    }, [toggleSelected])

    useEffect(() => {
        if (props.dataStatus === false){
            btnref6.current.classList.toggle(editbarCSS.unsaved, false)
            btnref7.current.classList.toggle(editbarCSS.rotatesave, false)
        }
        else{
            btnref6.current.classList.toggle(editbarCSS.unsaved, true)
            btnref7.current.classList.toggle(editbarCSS.rotatesave, true)
        }
    })

    return (
        <div>
            <div className={editbarCSS.editbar}>
                <div className={editbarCSS.navproject} ref={navRef}>
                    <button name='' className={editbarCSS.homebtn} ref={homeref} onClick={changeEdit}><img className={editbarCSS.logo} src={logo} alt="Logo_mit_Standorte.png" /></button>
                    <button name='/edit/general' className={editbarCSS.editbtn} ref={btnref1} onClick={changeEdit}>Allgemeine Information</button>
                    <button name='/edit/clicker' className={editbarCSS.editbtn} ref={btnref2} onClick={changeEdit}>Katalog</button>
                    <button name='/edit/judgement' className={editbarCSS.editbtn} ref={btnref3} onClick={changeEdit}>Risikobewertung</button>
                    <button name='/edit/summary' className={editbarCSS.editbtn} ref={btnref4} onClick={changeEdit}>Zusammenfassung</button>
                    <button name='/edit/pdf' className={editbarCSS.editbtn} ref={btnref5} onClick={changeEdit}>PDF</button>
                    <button name='/edit/save' className={editbarCSS.savebtn} ref={btnref6} onClick={props.saveData}>
                        <div ref={btnref7}>
                            <FaRegSave className={editbarCSS.saveimage}/>
                        </div>
                    </button>
                </div>
                <button ref={Barref} className={editbarCSS.Bar} onClick={openMenu}>
                    <FaBars/>
                </button>
            </div>
        </div>
    )
}