import summaryCSS from './summary.module.css'
import React from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Editbar from '../editbar'
import Askforsave from '../askforsave'
import _ from 'lodash'
import {Apiurl} from '../../../components/constants/constants'
import { useNavigate } from 'react-router-dom'
import { Loadingscreen } from '../../../components'

export default function Summary(){

    const token = localStorage.getItem('token')
    const [loadData, setloadData] = useState(false)
    const [dataStatus, setdataStatus] = useState(false)
    const [summaryData, setsummaryData] = useState([])
    const [PreviousData, setPreviousData] = useState([])
    const [checkData, setcheckData] = useState(false)
    const [siteswap, setsiteswap] = useState(false)
    const [siteswaprequest, setsiteswaprequest] = useState("")
    const navigate = useNavigate()

    function wait(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }

    const [loadStatus, setloadStatus] = useState(false)

    const closeload = async() => {
        await wait(1)
        setloadStatus(false)
    }
    

    const saveData = async() => {
        if (dataStatus===true){
            setloadStatus(true)
            const saveSummaryData = summaryData.map((item, index) => ({
                "value": item,
                "sequenz": parseInt(index)
            }))
            console.log(saveSummaryData)
            try{
                await axios({
                    method: 'POST',
                    url: Apiurl + "/postSummary?form_id=" + localStorage.getItem('selectedProject'),
                    data: saveSummaryData,
                    headers: {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
                }).then(e => {
                    console.log('Saved Data')
                    setPreviousData(summaryData)
                    setdataStatus(false)
                    console.log("data saved")
                    if (siteswap){
                        navigate('/home/companies/projects' + siteswaprequest)
                    }
                    closeload()
                })
            }
            catch(error){
                console.log(error)
                alert("Something went wrong!")
                closeload()
            }
        }
    }

    useEffect(() => {
        const getSummary = async() => {
            try {
                await axios({
                  method: 'GET',
                  url: Apiurl + '/loadForm?id=' + localStorage.getItem('selectedProject'),
                  headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
                }).then(response => {
                    console.log(response.data.summary)
                    setsummaryData(response.data.summary)
                    setPreviousData(_.cloneDeep(response.data.summary))
                    console.log("Zusammenfassung wurde geladen.")
                })
              }
              catch (error) {
              }
        }
        getSummary();
        setloadData(false)
    }, [loadData])

    const changeValue = (e, index) => {
        const updatedSummary = [...summaryData]
        updatedSummary[index] = e.target.value
        setsummaryData(updatedSummary)
        console.log(e.target.value)
        setcheckData(!checkData)
    }

    const addValue = () => {
        let newItem = ""
        setsummaryData([...summaryData, newItem])
        setcheckData(!checkData)
        console.log("Added Point")
    }

    const deleteValue = (e) => {
        console.log(e.target.id)
        const updatedSummary = summaryData.filter((item, index) => index !== parseInt(e.target.id))
        console.log("This is updatedSummary:", updatedSummary)
        setsummaryData(updatedSummary)
        setcheckData(!checkData)
        console.log("Item deleted")
    }

    const getrow = (point, index) => {
        console.log(index, point)
        return(
            <div className={summaryCSS.summarybox}>
                <p>{index+1}:</p>
                <textarea rows="2" max-rows="8" className={summaryCSS.summarytextarea} name={index} value={point} onChange={(e) => changeValue(e, index)}/>
                <button id={index} className={summaryCSS.summarydelete} onClick={deleteValue}>X</button>
            </div>

        )
    }

    const arraysAreEqual = (A, B) => {
        if (A.length !== B.length){
            return false
        }
        for (let i = 0; i < A.length; i++) {
          if (JSON.stringify(A[i]) !== JSON.stringify(B[i])) {
            return false;
          }
        }
        return true;
      }
    
    useEffect(() => {
        console.log("datacheck")
        if (arraysAreEqual(PreviousData, summaryData)){
            setdataStatus(false)
        }
        else{
            setdataStatus(true)
        }
       },[checkData])

    return (
        <div>
            <Loadingscreen loadStatus={loadStatus}/>
            <Editbar setsiteswap={setsiteswap} selectedHover = "summary" saveData={saveData} dataStatus={dataStatus} setsiteswaprequest={setsiteswaprequest}/>
            <Askforsave siteswap={siteswap} setsiteswap={setsiteswap} saveData={saveData} dataStatus={dataStatus} siteswaprequest={siteswaprequest} setsiteswaprequest={setsiteswaprequest}/>
            <div className={summaryCSS.mainbox}>
                <div className={summaryCSS.titlebox}>
                    <p>Zusammenfassung</p>
                    <button className={summaryCSS.summaryadd} onClick={addValue}>Add</button>
                </div>
                <div className={summaryCSS.rows}>
                    {summaryData.map((row, index) => (
                        getrow(row, index)
                    ))}
                </div>
            </div>
        </div>
    )
}