import { useEffect, useRef } from 'react'
import impressumCSS from './impressum.module.css'

export default function Impressum(props){
    const {setimpressum, impressum, setdatenschutz} = props
    const impressumRef = useRef()
    const btnRef = useRef()
    
    const closewindow = () => {
        setimpressum(false)
    }

    useEffect(() => {
        if (impressum){
            setdatenschutz(false)
            impressumRef.current.classList.toggle(impressumCSS.showimpressum, true)
            btnRef.current.classList.toggle(impressumCSS.showbtn, true)
        }
        else{
            impressumRef.current.classList.toggle(impressumCSS.showimpressum, false)
            btnRef.current.classList.toggle(impressumCSS.showbtn, false)
        }
    }, [impressum])

        return (
            <div ref={impressumRef} className={impressumCSS.mainbox}>
                <h1>IMPRESSUM</h1>
                <div className={impressumCSS.textbox}>
                    <p>b.power GmbH</p>
                    <p>Mandlstrasse 22, 80802 München</p>
                    <p>Tel.: +49 (0) 89 / 330 29 399-0</p>
                    <p>Fax: +49 (0) 89 / 330 29 3-999</p>
                    <p>E-Mail: info(at)bpower.de</p>
                    <p>Web: www.bpower.de</p>
                    <p>Geschäftsführer: Michael Ballmann</p>
                    <p>Prokurist: Thomas Hoffmann</p>
                    <p>Umsatzsteuer-Identifikationsnummer: DE 248723027</p>
                    <p>Handelsregister: AG München HRB 162537</p>
                    <p>Zuständige Aufsichtsbehörde: Bundesagentur fürArbeit Regionaldirektion Bayern, Thomas-Mann-Strasse 50, 90471 Nürnberg</p>
                </div>
                <button ref={btnRef} className={impressumCSS.btn_cancel} onClick={closewindow}>
                    Schließen
                </button>
            </div>
        )

}