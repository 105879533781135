import deletecompanyCSS from './deletecompany.module.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {Apiurl} from '../../components/constants/constants'

export default function Deletecompany(props){

    //Functions
    
    const navigate = useNavigate();

    const confirmDeleteCompany = async() => {
        if (props.pin === props.requiredpin){
            console.log('Confirm')
            try {
                const token = localStorage.getItem('token')
                await axios({
                    method: 'DELETE',
                    url: Apiurl + '/deleteCompany?company_id=' + props.selectedCompanyId,
                    headers: {Authorization: `Bearer ${token}`}
                }).then(response => {
                    props.setLoadAllCompanies(!props.LoadAllCompanies)
                    props.setselectedCompanyId("")

                    props.dRef.current.classList.toggle(props.companyselectionCSS.responsive_deleterequest, false)
                    props.blurRef.current.classList.toggle(props.companyselectionCSS.responsive_blur, false)
                    props.delay(1000)
                    props.dRef.current.classList.toggle(props.companyselectionCSS.responsive_transition, false)
                    props.setpin("")
                })
            }
            catch(e){
                alert(e.response.data.detail)
                navigate('/')
            }
        }
        else {
            alert("PIN nicht korrekt.")
            console.log('declined')
        }
    }

    const cancelDeleteCompany = () => {
        console.log('Delete canceled')
    }

        return (
            <div className={deletecompanyCSS.box}>
                <div className={deletecompanyCSS.deleteconfirm}>
                    <h1>{props.selectedCompany} löschen!</h1>
                    <p>Alle Daten dieser Firma werden unwiderruflich gelöscht!</p>
                    <input ref={props.inpRef} value={props.pin} type="password" name="Ersteller" placeholder='&#9679;&#9679;&#9679;&#9679;' maxLength="4" className={deletecompanyCSS.pinput} onChange={(e) => props.setpin(e.target.value)}/>
                    <div className={deletecompanyCSS.btn_box}>
                        <button ref={props.btnRef} className={deletecompanyCSS.btn_confirm} onClick={confirmDeleteCompany}>
                            Bestätigen
                        </button>
                        <button ref={props.btn2Ref} className={deletecompanyCSS.btn_cancel} onClick={cancelDeleteCompany}>
                            Abbrechen
                        </button>
                    </div>
                </div>
            </div>

        )

}




    // const confirmDeleteCompany = async() => {
    //     if (props.pin === props.requiredpin){
    //         console.log('Confirm')
    //         try {
    //         fetch('http://193.218.21.125:8080/deleteCompany?company_id=' + props.selectedCompanyId, {
    //             method: 'DELETE',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({})
    //         })
    //             .then(response => {
    //             response.json()
    //             props.setLoadAllCompanies(!props.LoadAllCompanies)
    //             props.setselectedCompanyId("")
                
    //             })
    //             .catch(error => console.error(error))
    //         }
    //         finally {
    //             props.dRef.current.classList.toggle(props.companyselectionCSS.responsive_deleterequest, false)
    //             props.blurRef.current.classList.toggle(props.companyselectionCSS.responsive_blur, false)
    //             await props.delay(1000)
    //             props.dRef.current.classList.toggle(props.companyselectionCSS.responsive_transition, false)
    //             props.setpin("")
    //         //setLoadAllCompanies(!LoadAllCompanies)
    //         }
    //     }
    //     else {
    //         alert("PIN nicht korrekt.")
    //         console.log('declined')
    //     }
    // }