import React, { useState, useRef } from "react"
import passwordchangeCSS from './passwordchange.module.css'
import axios from "axios"
import { Apiurl } from "../constants/constants"
import { useEffect } from "react"

export default function Passwordchange(props){
    const passwordRef = useRef(false)
    const passwordmainRef = useRef(false)

    const [oldpw, setoldpw] = useState("")
    const [newpw, setnewpw] = useState("")
    const [newpw2, setnewpw2] = useState("")

    const changepassword = async() => {
        const token = localStorage.getItem('token')
        console.log("Hallo")
        if (newpw === newpw2){
            try{
                await axios({
                    method: 'PUT',
                    url: Apiurl + '/changeUserPassword',
                    params: {new_password: newpw, old_password: oldpw},
                    headers: {Authorization: `Bearer ${token}`}
                }).then(response => {
                    alert('Passwort wurde geändert.')
                    setoldpw("")
                    setnewpw("")
                    setnewpw2("")
                })
            }
            catch(e){
                console.log(e.response.data.detail)
            }
        }
        else{
            alert('Passwort stimmt nicht überein!')
        }
    }

    useEffect(() => {
        if (props.changepassword === true){
            passwordRef.current.classList.toggle(passwordchangeCSS.showchangepw, true)
            passwordmainRef.current.classList.toggle(passwordchangeCSS.mainbox_blur, true)
        }
        else{
            passwordRef.current.classList.toggle(passwordchangeCSS.showchangepw, false)
            passwordmainRef.current.classList.toggle(passwordchangeCSS.mainbox_blur, false)
        }
       }, [props.changepassword])

    useEffect(() => {
        //closeDelete
        let showDelete = async (e) => {
            if (passwordmainRef.current.contains(e.target) && !passwordRef.current.contains(e.target)){
                passwordRef.current.classList.toggle(passwordchangeCSS.showchangepw, false)
                passwordmainRef.current.classList.toggle(passwordchangeCSS.mainbox_blur, false)
                props.setchangepassword(false)
            }
        };
        document.addEventListener("mousedown", showDelete)

        return() =>{
            document.removeEventListener("mousedown", showDelete)
        }
    });

    return(
        <div ref={passwordmainRef} className={passwordchangeCSS.mainbox}>
            <div ref={passwordRef} className={passwordchangeCSS.subbox}>
                <p className={passwordchangeCSS.title}> Passwortänderung</p>
                <div className={passwordchangeCSS.inputbox}>
                    <div className={passwordchangeCSS.passwordbox}>
                        <p className={passwordchangeCSS.subtitle}>Altes Passwort:</p>
                        <input className={passwordchangeCSS.input} value={oldpw} type="password" onChange={(e) => setoldpw(e.target.value)}></input>
                    </div>
                    <div className={passwordchangeCSS.passwordbox}>
                        <p className={passwordchangeCSS.subtitle}>Neues Passwort:</p>
                        <input className={passwordchangeCSS.input} value={newpw} type="password" onChange={(e) => setnewpw(e.target.value)}></input>
                    </div>
                    <div className={passwordchangeCSS.passwordbox}>
                        <p className={passwordchangeCSS.subtitle}>Neues Passwort wiederholen:</p>
                        <input className={passwordchangeCSS.input} value={newpw2} type="password" onChange={(e) => setnewpw2(e.target.value)}></input>
                    </div>
                </div>
                <div>
                    <button className={passwordchangeCSS.confirm} onClick={changepassword}>Bestätigen</button>
                    <button className={passwordchangeCSS.decline} onClick={(e) => props.setchangepassword(false)}>Abbrechen</button>
                </div>
            </div>
        </div>
    )
}