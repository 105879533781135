import riskjudgementCSS from './riskjudgement.module.css'
import React from 'react'

export default function Riskjudgement(props){
    const prepUpdateRisk = (value) => {
        console.log('ausgeführt')
        console.log(value[0])
    }

    return(
        <div className={riskjudgementCSS.mainbox}>
            <div className={riskjudgementCSS.btnbox}>
                <p className={riskjudgementCSS.header}>Risikobewertungsmatrix</p>
            </div>
            <div className={riskjudgementCSS.btnbox}>
                <button className={riskjudgementCSS.emptybox1}></button>
                <button className={riskjudgementCSS.emptybox2}>Schadensausmaß</button>
                <button className={riskjudgementCSS.title1}>sehr hoch</button>
                <button className={riskjudgementCSS.title2}>hoch</button>
                <button className={riskjudgementCSS.title3}>gering</button>
                <button className={riskjudgementCSS.title4}>sehr gering</button>
            </div>
            <div className={riskjudgementCSS.btnbox}>
                <button className={riskjudgementCSS.emptybox3}>Eintrittswahrscheinlichkeit</button>
                <button className={riskjudgementCSS.emptybox4}></button>
                <button className={riskjudgementCSS.title1}>4</button>
                <button className={riskjudgementCSS.title2}>3</button>
                <button className={riskjudgementCSS.title3}>2</button>
                <button className={riskjudgementCSS.title4}>1</button>
            </div>
            <div className={riskjudgementCSS.btnbox}>
                <button className={riskjudgementCSS.title11}>Häufig</button>
                <button className={riskjudgementCSS.title5}>5</button>
                <button value='[20, 5, 4]' className={riskjudgementCSS.btn1} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"20 (HOCH)"}</button>
                <button value='[15, 5, 3]' className={riskjudgementCSS.btn1} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"15 (HOCH)"}</button>
                <button value='[10, 5, 2]' className={riskjudgementCSS.btn2} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"10 (ERNST)"}</button>
                <button value='[5, 5, 1]' className={riskjudgementCSS.btn3} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"5 (MITTEL)"}</button>
            </div>
            <div className={riskjudgementCSS.btnbox}>
                <button className={riskjudgementCSS.title12}>Wahrscheinlich</button>
                <button className={riskjudgementCSS.title6}>4</button>
                <button value='[16, 4, 4]' className={riskjudgementCSS.btn1} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"16 (HOCH)"}</button>
                <button value='[12, 4, 3]' className={riskjudgementCSS.btn1} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"12 (HOCH)"}</button>
                <button value='[8, 4, 2]' className={riskjudgementCSS.btn2} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"8 (ERNST)"}</button>
                <button value='[4, 4, 1]' className={riskjudgementCSS.btn3} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"4 (MITTEL)"}</button>
            </div>
            <div className={riskjudgementCSS.btnbox}>
                <button className={riskjudgementCSS.title13}>Gelegentlich</button>
                <button className={riskjudgementCSS.title7}>3</button>
                <button value='[12, 3, 4]' className={riskjudgementCSS.btn1} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"12 (HOCH)"}</button>
                <button value='[9, 3, 3]' className={riskjudgementCSS.btn2} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"9 (ERNST)"}</button>
                <button value='[6, 3, 2]' className={riskjudgementCSS.btn3} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"6 (MITTEL)"}</button>
                <button value='[3, 3, 1]' className={riskjudgementCSS.btn3} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"3 (MITTEL)"}</button>
            </div>
            <div className={riskjudgementCSS.btnbox}>
                <button className={riskjudgementCSS.title14}>Selten</button>
                <button className={riskjudgementCSS.title8}>2</button>
                <button value='[8, 2, 4]' className={riskjudgementCSS.btn2} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"8 (ERNST)"}</button>
                <button value='[6, 2, 3]' className={riskjudgementCSS.btn3} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"6 (MITTEL)"}</button>
                <button value='[4, 2, 2]' className={riskjudgementCSS.btn3} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"4 (MITTEL)"}</button>
                <button value='[2, 2, 1]' className={riskjudgementCSS.btn4} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"2 (GERING)"}</button>
            </div>
            <div className={riskjudgementCSS.btnbox}>
                <button className={riskjudgementCSS.title15}>Unwahrscheinlich</button>
                <button className={riskjudgementCSS.title9}>1</button>
                <button value='[4, 1, 4]' className={riskjudgementCSS.btn3} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"4 (MITTEL)"}</button>
                <button value='[3, 1, 3]' className={riskjudgementCSS.btn3} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"3 (MITTEL)"}</button>
                <button value='[2, 1, 2]' className={riskjudgementCSS.btn4} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"2 (GERING)"}</button>
                <button value='[1, 1, 1]' className={riskjudgementCSS.btn4} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"1 (GERING)"}</button>
            </div>
            <div className={riskjudgementCSS.btnbox}>
                <button className={riskjudgementCSS.title16}>Ausgeschlossen</button>
                <button className={riskjudgementCSS.title10}>0</button>
                <button value='[0, 0, 0]' className={riskjudgementCSS.widebtn} onClick={(e) => props.updateRisk(JSON.parse(e.target.value))}>{"0 (BESEITIGT)"}</button>
            </div>
        </div>
    )
}