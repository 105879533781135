import { useEffect, useRef } from 'react'
import datenschutzCSS from './datenschutz.module.css'

export default function Datenschutz(props){
    const {setdatenschutz, datenschutz, setimpressum} = props
    const datenschutzRef = useRef()
    const datenschutzRef2 = useRef()
    const btnRef = useRef()
    
    const closewindow = () => {
        setdatenschutz(false)
    }

    useEffect(() => {
        if (datenschutz){
            setimpressum(false)
            datenschutzRef.current.classList.toggle(datenschutzCSS.showdatenschutz, true)
            btnRef.current.classList.toggle(datenschutzCSS.showbtn, true)
            datenschutzRef2.current.classList.toggle(datenschutzCSS.showtextbox, true)
        }
        else{
            datenschutzRef.current.classList.toggle(datenschutzCSS.showdatenschutz, false)
            btnRef.current.classList.toggle(datenschutzCSS.showbtn, false)
            datenschutzRef2.current.classList.toggle(datenschutzCSS.showtextbox, false)
        }
    }, [datenschutz])

        return (
            <div ref={datenschutzRef} className={datenschutzCSS.mainbox}>
                <h1 className={datenschutzCSS.title}>Datenschutz</h1>
                <div ref={datenschutzRef2} className={datenschutzCSS.textbox}>
                    <p className={datenschutzCSS.text}>
                    Wir legen größten Wert auf den Schutz Ihrer Daten und die Wahrung Ihrer Privatsphäre. Nachstehend informieren wir Sie deshalb über die Erhebung und Verwendung persönlicher 
                    Daten bei Nutzung unserer Website. Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) 
                    innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf 
                    (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf 
                    die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).<br/><br/>
                    Mehr unter: 
                    </p>
                    <a className={datenschutzCSS.link} href='https://www.bpower.de/' target="_blank" rel="noopener noreferrer">b.power GmbH</a>
                    <h2 className={datenschutzCSS.subtitle}>Verantwortlicher</h2>
                    <p className={datenschutzCSS.text}>
                        Michael Ballmann, Geschäftsführer<br />
                        Mandlstraße 22 80802 München Deutschland<br />
                        E-Mailadresse: info@bpower.de<br />
                        Geschäftsführender Gesellschafter: Michael Ballmann<br />
                        Link zum Impressum: www.bpower.de/impressum<br />
                        Kontakt Datenschutzbeauftragter: peter.sudhaus@bpower.de
                    </p>
                    <h2 className={datenschutzCSS.subtitle}>Kategorien betroffener Personen</h2>
                    <p className={datenschutzCSS.text}>
                        Nutzer und Nutzerinnen des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).
                    </p>
                    <h2 className={datenschutzCSS.subtitle}>Zweck der Verarbeitung</h2>
                    <p className={datenschutzCSS.text}>
                        Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.
                    </p>
                    <h2 className={datenschutzCSS.subtitle}>Maßgebliche Rechtsgrundlagen</h2>
                    <p className={datenschutzCSS.text}>
                        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. 
                        Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: 
                        Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage 
                        für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist 
                        Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. 
                        c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, 
                        dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, 
                        dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
                    </p>
                    <h2 className={datenschutzCSS.subtitle}>Hosting</h2>
                    <p className={datenschutzCSS.text}>
                    Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, 
                    Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs 
                    dieses Onlineangebotes einsetzen.
                    </p>
                    <h2 className={datenschutzCSS.subtitle}>Cookies</h2>
                    <p className={datenschutzCSS.text}>
                        In dieser Webanwendung werden ausschließlich technisch notwendige Cookies eingesetzt, um die Seitennavigation und Zugriffe auf die Accountspezifischen Daten zu ermöglichen. Diese werden benötigt,
                        um die Navigation und die Kommunikation mit dem Server zu ermöglichen. Die Cookies werden dauerhauft gespeichert und können durch explizites ausloggen entfernt.
                    </p>
                    {/* <h2 className={datenschutzCSS.subtitle}>Vertragliche Leistungen</h2>
                    <p className={datenschutzCSS.text}>
                        Wir verarbeiten die Daten unserer Vertragspartner und Interessenten sowie anderer Auftraggeber, Kunden, Mandanten, Klienten oder Vertragspartner 
                        (einheitlich bezeichnet als „Vertragspartner“) entsprechend Art. 6 Abs. 1 lit. b. DSGVO, um ihnen gegenüber unsere vertraglichen oder vorvertraglichen 
                        Leistungen zu erbringen. Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich 
                        nach dem zugrundeliegenden Vertragsverhältnis.<br/><br/>
                        Besondere Kategorien personenbezogener Daten verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile einer beauftragten oder vertragsgemäßen Verarbeitung sind.<br/><br/>
                        Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe, sofern diese 
                        für die Vertragspartner nicht evident ist, hin. Eine Offenlegung an externe Personen oder Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags erforderlich ist. 
                        Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten, handeln wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben.
                    </p> */}
                    {/* <h2 className={datenschutzCSS.subtitle}>Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung</h2>
                    <p className={datenschutzCSS.text}>
                        Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. 
                        der Archivierung. Hierbei verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung unserer vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen 
                        sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden, Interessenten, Geschäftspartner und Websitebesucher betroffen. 
                        Der Zweck und unser Interesse an der Verarbeitung liegt in der Administration, Finanzbuchhaltung, Büroorganisation, Archivierung von Daten, also Aufgaben die der Aufrechterhaltung 
                        unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf vertragliche Leistungen und die 
                        vertragliche Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten genannten Angaben.<br/><br/>
                        Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere Gebührenstellen und Zahlungsdienstleister.<br/><br/>
                        Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer 
                        Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich dauerhaft.
                    </p> */}
                    <h2 className={datenschutzCSS.subtitle}>Google Fonts</h2>
                    <p className={datenschutzCSS.text}>
                        Wir binden die Schriftarten (“Google Fonts”) des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. <br/>
                        Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out: https://adssettings.google.com/authenticated.
                    </p>
                    <h2 className={datenschutzCSS.subtitle}>Icons</h2>
                    <p className={datenschutzCSS.text}>
                    Die von uns verwendeten Icons stammen aus den folgenden Icon-Sets, die unter Open-Source-Lizenzen stehen:

                    Ant Design Icons: bereitgestellt von Ant Design, lizenziert unter der MIT-Lizenz.
                    Font Awesome: bereitgestellt von Fonticons, Inc., lizenziert unter der SIL Open Font License.

                    Für weitere Informationen zu den Lizenzen und Nutzungsbedingungen der Icons besuchen Sie bitte die entsprechenden Webseiten:<br/><br/>
                    </p>
                    <a className={datenschutzCSS.link} href='https://github.com/ant-design/ant-design-icons' target="_blank" rel="noopener noreferrer">Ant Design Icons</a>
                    <a className={datenschutzCSS.link} href='https://fontawesome.com/' target="_blank" rel="noopener noreferrer">Font Awesome</a>
                    {/* <h2 className={datenschutzCSS.subtitle}></h2>
                    <p className={datenschutzCSS.text}>

                    </p> */}
                </div>
                <div className={datenschutzCSS.btnbox}>
                    <button ref={btnRef} className={datenschutzCSS.btn} onClick={closewindow}>
                        Schließen
                    </button>
                </div>
            </div>
        )

}