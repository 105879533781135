import React, { useEffect, useState } from 'react'
import {Navbar} from "../../components"

export default function Status(){

    const [A, setA] = useState(false)
    const [B, setB] = useState(false)
    const [firstload, setfirstload] = useState(true)

    const [Variable, setVariable] = useState(1)
    useEffect(() => {
        console.log("A")
        setVariable(5)
        setfirstload(false)
        setB(!B)
    }, [A])

    useEffect(() => {
        if (!firstload){
            console.log("B")
            console.log(Variable)
        }
    }, [B])

    return (
        <div>
            <Navbar/>
        </div>
    )
}