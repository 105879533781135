import React from "react"
import LoadingscreenCSS from './loadingscreen.module.css'
import loadingimage from '../../assets/bpowermini2.png'
import {useRef, useEffect} from 'react'

export default function Loadingscreen(props){
    const loadingRef = useRef(false)
    const loadingScreenRef = useRef(false)

    function wait(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }

    const startloading = () => {
        loadingRef.current.classList.toggle(LoadingscreenCSS.load, true)
        loadingScreenRef.current.classList.toggle(LoadingscreenCSS.loadScreen, true)
    }

    const stoploading = async() => {
        loadingRef.current.classList.toggle(LoadingscreenCSS.stopload, true)
        loadingScreenRef.current.classList.toggle(LoadingscreenCSS.loadScreen, false)
        await wait(.2)
        loadingRef.current.classList.toggle(LoadingscreenCSS.stopload, false)
        loadingRef.current.classList.toggle(LoadingscreenCSS.load, false)
    }

    useEffect(() => {
        if (props.loadStatus === true){
            startloading()
        }
        else{
            stoploading()
        }
       }, [props.loadStatus])

    return(
        <div ref={loadingScreenRef} className={LoadingscreenCSS.mainbox}>
            <img ref={loadingRef} className={LoadingscreenCSS.imgbox} src={loadingimage} alt="Load"></img>
        </div>
    )
}