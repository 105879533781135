import React from 'react'
import './App.css'
import {Login, Register, Home, Companyselection, Projectselection, Editbar, General, Clicker, Judgement, Projectsettings, PDF, Status, Summary} from './pages';
import {Routes, Route} from 'react-router-dom'

const App = () => {

  return (
      <Routes>
        <Route path='/' element={<Login/>} />
        {/* <Route path='/register' element={<Register/>} /> */}
        <Route path='/home' element={<Home/>} />
        <Route path='/home/companies' element={<Companyselection/>}/>
        <Route path='/home/companies/projects' element={<Projectselection/>}/>
        <Route path='/home/companies/projects/edit' element={<Editbar/>}/>
        <Route path='/home/companies/projects/edit/general' element={<General/>}/>
        <Route path='/home/companies/projects/edit/clicker' element={<Clicker/>}/>
        <Route path='/home/companies/projects/edit/judgement' element={<Judgement/>}/>
        <Route path='/home/companies/projects/edit/summary' element={<Summary/>}/>
        <Route path='/home/companies/projects/edit/settings' element={<Projectsettings/>}/>
        <Route path='/home/companies/projects/edit/pdf' element={<PDF/>}/>
        <Route path='/home/status' element={<Status/>}/>
      </Routes>
  )
}

export default App