import axios from 'axios'
import changeProjectNameCSS from './changeProjectName.module.css'
import {Apiurl} from '../../components/constants/constants'
import { useState } from 'react'

export default function ChangeProjectName(props){
    //Variables
    const [changedName, setchangedName] = useState("")

    //Functions
    // const confirmNameChange = () => {
    //     //Change the name locally after confirmed change on database
    //     let newprojects = [...props.projects]
    //     console.log(newprojects)
    //     for (let i = 0; i < newprojects.length; i++) {
    //         console.log(newprojects[i])
    //         if (newprojects[i].project_name === props.selectedProject && newprojects[i].id === parseInt(props.selectedProjectId)) {
    //             newprojects[i].project_name = changedName;
    //             props.setProjects(newprojects)

    //             props.cRef.current.classList.toggle(props.projectselectionCSS.responsive_deleterequest, false)
    //             props.blurRef.current.classList.toggle(props.projectselectionCSS.responsive_blur, false)
    //             props.delay(1000)
    //             props.cRef.current.classList.toggle(props.projectselectionCSS.responsive_transition, false)
    //             setchangedName("")
    //             return;
    //         }
    //     }
    // }

    const confirmNameChange = async() => {
        const token = localStorage.getItem('token')
        console.log('Project_ID:', props.selectedProjectId, 'Neuer Name:', changedName)
        if (changedName !== props.selectedProject){
            try{
                await axios({
                    method: 'POST',
                    url: Apiurl + '/changeProjectName',
                    params: {project_id: props.selectedProjectId, newName: changedName},
                    headers: {Authorization: `Bearer ${token}`}
                }).then(response => {
                    let newprojects = [...props.projects]
                    console.log(newprojects)
                    for (let i = 0; i < newprojects.length; i++) {
                        console.log(newprojects[i])
                        if (newprojects[i].project_name === props.selectedProject && newprojects[i].id === parseInt(props.selectedProjectId)) {
                            newprojects[i].project_name = changedName;
                            props.setProjects(newprojects)
            
                            props.cRef.current.classList.toggle(props.projectselectionCSS.responsive_deleterequest, false)
                            props.blurRef.current.classList.toggle(props.projectselectionCSS.responsive_blur, false)
                            props.delay(1000)
                            props.cRef.current.classList.toggle(props.projectselectionCSS.responsive_transition, false)
                            setchangedName("")
                            return;
                        }
                    }
                })
            }
            catch(e){
                console.log(e.response.data.detail)
            }
        }
        else{
            alert('Das Projekt heißt bereits', changedName, '!')
        }
    }

    const cancelNameChange = () => {
        console.log('Namechange canceled')
    }

        return (
            <div className={changeProjectNameCSS.box}>
                <div className={changeProjectNameCSS.deleteconfirm}>
                    <h1>{props.selectedProject} umbenennen</h1>
                    <p></p>
                    <input ref={props.inpRef2} value={changedName} name="Ersteller" className={changeProjectNameCSS.pinput} onChange={(e) => setchangedName(e.target.value)}/>
                    <div className={changeProjectNameCSS.btn_box}>
                        <button ref={props.btnRef2} className={changeProjectNameCSS.btn_confirm} onClick={confirmNameChange}>
                            Bestätigen
                        </button>
                        <button ref={props.btn2Ref2} className={changeProjectNameCSS.btn_cancel} onClick={cancelNameChange}>
                            Abbrechen
                        </button>
                    </div>
                </div>
            </div>

        )

}