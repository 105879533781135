import clickerCSS from './clicker.module.css'
import Editbar from '../editbar'
import Askforsave from '../askforsave'
import { useEffect, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {Apiurl} from '../../../components/constants/constants'
import { useNavigate } from 'react-router-dom'
import { Loadingscreen } from '../../../components'

export default function Clicker(){
    const navigate = useNavigate()

    function wait(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }

    const [loadStatus, setloadStatus] = useState(false)

    const closeload = async() => {
        await wait(1)
        setloadStatus(false)
    }
    //Loaders
    const [LoadClickerData, setLoadClickerData] = useState(true)
    const [Previous, setPrevious] = useState([
        {form_field: "1", form_field_info: "Mechanische Gefährdungen", form_value: 0}, {form_field: "1.1", form_field_info: "Ungeschützt bewegte Maschinenteile", form_value: 0}, {form_field: "1.2", form_field_info: "Teile mit gefährlichen Oberflächen", form_value: 0}, {form_field: "1.3", form_field_info: "Bewegte Transportmittel, bewegte Arbeitsmittel", form_value: 0}, {form_field: "1.4", form_field_info: "Unkontrolliert bewegte Teile", form_value: 0}, {form_field: "1.5", form_field_info: "Sturz, Ausrutschen, Stolpern, Umknicken", form_value: 0}, {form_field: "1.6", form_field_info: "Absturz", form_value: 0},
        {form_field: "2", form_field_info: "Elektrische Gefährdung", form_value: 0}, {form_field: "2.1", form_field_info: "Körperdurchströmung", form_value: 0}, {form_field: "2.2", form_field_info: "Störlichtbogen", form_value: 0}, {form_field: "2.3", form_field_info: "Elektrostatische Aufladung", form_value: 0},
        {form_field: "3", form_field_info: "Gefahrstoffe", form_value: 0}, {form_field: "3.1", form_field_info: "Mangelnde Hygiene beim Umgang mit Gefahrstoffen", form_value: 0}, {form_field: "3.2", form_field_info: "Einatmen von Gefahrstoffen", form_value: 0}, {form_field: "3.3", form_field_info: "Hautkontakt mit Gefahrstoffen", form_value: 0}, {form_field: "3.4", form_field_info: "Transport von Gefahrstoffen", form_value: 0}, {form_field: "3.5", form_field_info: "Physikalische- Chemische Gefährdung", form_value: 0}, {form_field: "3.6", form_field_info: "Reproduktionstoxische Stoffe (GHS Kat1A, 1B, 2)", form_value: 0}, {form_field: "3.7", form_field_info: "Krebserregende und mutagene Stoffe", form_value: 0},
        {form_field: "4", form_field_info: "Biologische Gefährdung", form_value: 0}, {form_field: "4.1", form_field_info: "Infektionsgefährdung durch pathogene Mikroorganismen", form_value: 0}, {form_field: "4.2", form_field_info: "Sensibilisierende und toxische Wirkung von Mikroorganismen", form_value: 0},
        {form_field: "5", form_field_info: "Brand- und Explosionsgefährdung", form_value: 0}, {form_field: "5.1", form_field_info: "Brennbare Feststoffe, Flüssigkeiten und Gase", form_value: 0}, {form_field: "5.2", form_field_info: "Explosionsfähige Atmosphäre", form_value: 0}, {form_field: "5.3", form_field_info: "Verbaute Explosivstoffe", form_value: 0},
        {form_field: "6", form_field_info: "Thermische Gefährdung", form_value: 0}, {form_field: "6.1", form_field_info: "Heiße Medien / Oberflächen", form_value: 0}, {form_field: "6.2", form_field_info: "Kalte Medien / Oberflächen", form_value: 0},
        {form_field: "7", form_field_info: "Gefährdung durch spezielle physikalische Einwirkungen", form_value: 0}, {form_field: "7.1", form_field_info: "Lärm", form_value: 0}, {form_field: "7.2", form_field_info: "Ultraschall, Infraschall", form_value: 0}, {form_field: "7.3", form_field_info: "Ganzkörpervibration", form_value: 0}, {form_field: "7.4", form_field_info: "Hand- Arm- Vibration", form_value: 0}, {form_field: "7.5", form_field_info: "Künstliche optische Strahlung (IR, UV, LASER)", form_value: 0}, {form_field: "7.6", form_field_info: "Ionisierende Strahlung (Teilchenstrahlung)", form_value: 0}, {form_field: "7.7", form_field_info: "Elektromagnetische Strahlung", form_value: 0}, {form_field: "7.8", form_field_info: "Unter- / Überdruck", form_value: 0},
        {form_field: "8", form_field_info: "Gefährdung durch Arbeitsumgebung", form_value: 0}, {form_field: "8.1", form_field_info: "Klima (Raumklima, Lüftung, Witterungseinflüsse, Temperatur, Sonnenlicht)", form_value: 0}, {form_field: "8.2", form_field_info: "Beleuchtung. Lichtverhältnisse, Erkennbarkeit von opt. Signalen / Bildschirmen", form_value: 0}, {form_field: "8.3", form_field_info: "Ersticken (Sauerstoffreduzierte Atmosphäre), Ertrinken", form_value: 0}, {form_field: "8.4", form_field_info: "Unzureichende Flucht- und Verkehrswege, unzureichende Sicherheits- und Gesundheitsschutzkennzeichnung", form_value: 0}, {form_field: "8.5", form_field_info: "Unzureichende Bewegungsfläche am Arbeitsplatz, ungünstige Anordnung des Arbeitsplatzes, unzureichende Pausen- und Sanitärräume", form_value: 0}, {form_field: "8.6", form_field_info: "Unzureichend gestaltete Mensch- Maschine- Schnittstelle", form_value: 0},
        {form_field: "9", form_field_info: "Physikalische Belastung/Arbeitsschwere", form_value: 0}, {form_field: "9.1", form_field_info: "Heben, Halten, Tragen", form_value: 0}, {form_field: "9.2", form_field_info: "Ziehen / Schieben", form_value: 0}, {form_field: "9.3", form_field_info: "Manuelle Arbeit mit geringen Körperkräften", form_value: 0}, {form_field: "9.4", form_field_info: "Zwangshaltung (erzwungene Körperhaltung)", form_value: 0}, {form_field: "9.5", form_field_info: "Steigen, Klettern", form_value: 0}, {form_field: "9.6", form_field_info: "Arbeiten mit erhöhten Kraftanstrengungen und/oder Krafteinwirkungen", form_value: 0},
        {form_field: "10", form_field_info: "Psychische Faktoren", form_value: 0}, {form_field: "10.1", form_field_info: "Ungenügend gestaltete Arbeitsaufgaben", form_value: 0}, {form_field: "10.2", form_field_info: "Ungenügend gestaltete Arbeitsorganisation", form_value: 0}, {form_field: "10.3", form_field_info: "Ungenügend gestaltete soziale Bedingungen", form_value: 0}, {form_field: "10.4", form_field_info: "Ungenügend gestalteter Arbeitsplatz & Arbeitsplatzumgebungsbedingungen", form_value: 0},
        {form_field: "11", form_field_info: "Arbeitsschutzorganisation und sonstige Gefährdung", form_value: 0}, {form_field: "11.1", form_field_info: "Persönliche Schutzausstattung", form_value: 0}, {form_field: "11.2", form_field_info: "Verantwortlichkeiten, Unterweisungen", form_value: 0}, {form_field: "11.3", form_field_info: "Verhalten in Notfällen, Erste Hilfe", form_value: 0}, {form_field: "11.4", form_field_info: "Arbeitsmedizinische- und Arbeitssicherheitsbetreuung", form_value: 0}, {form_field: "11.5", form_field_info: "Gefährdung durch Dritte", form_value: 0}, {form_field: "11.6", form_field_info: "Gefährdung durch Tiere", form_value: 0}, {form_field: "11.7", form_field_info: "Gefährdung durch pflanzliche Produkte", form_value: 0}
    ])
    const [clickerData, setClickerData] = useState([
        {form_field: "1", form_field_info: "Mechanische Gefährdungen", form_value: 0}, {form_field: "1.1", form_field_info: "Ungeschützt bewegte Maschinenteile", form_value: 0}, {form_field: "1.2", form_field_info: "Teile mit gefährlichen Oberflächen", form_value: 0}, {form_field: "1.3", form_field_info: "Bewegte Transportmittel, bewegte Arbeitsmittel", form_value: 0}, {form_field: "1.4", form_field_info: "Unkontrolliert bewegte Teile", form_value: 0}, {form_field: "1.5", form_field_info: "Sturz, Ausrutschen, Stolpern, Umknicken", form_value: 0}, {form_field: "1.6", form_field_info: "Absturz", form_value: 0},
        {form_field: "2", form_field_info: "Elektrische Gefährdung", form_value: 0}, {form_field: "2.1", form_field_info: "Körperdurchströmung", form_value: 0}, {form_field: "2.2", form_field_info: "Störlichtbogen", form_value: 0}, {form_field: "2.3", form_field_info: "Elektrostatische Aufladung", form_value: 0},
        {form_field: "3", form_field_info: "Gefahrstoffe", form_value: 0}, {form_field: "3.1", form_field_info: "Mangelnde Hygiene beim Umgang mit Gefahrstoffen", form_value: 0}, {form_field: "3.2", form_field_info: "Einatmen von Gefahrstoffen", form_value: 0}, {form_field: "3.3", form_field_info: "Hautkontakt mit Gefahrstoffen", form_value: 0}, {form_field: "3.4", form_field_info: "Transport von Gefahrstoffen", form_value: 0}, {form_field: "3.5", form_field_info: "Physikalische- Chemische Gefährdung", form_value: 0}, {form_field: "3.6", form_field_info: "Reproduktionstoxische Stoffe (GHS Kat1A, 1B, 2)", form_value: 0}, {form_field: "3.7", form_field_info: "Krebserregende und mutagene Stoffe", form_value: 0},
        {form_field: "4", form_field_info: "Biologische Gefährdung", form_value: 0}, {form_field: "4.1", form_field_info: "Infektionsgefährdung durch pathogene Mikroorganismen", form_value: 0}, {form_field: "4.2", form_field_info: "Sensibilisierende und toxische Wirkung von Mikroorganismen", form_value: 0},
        {form_field: "5", form_field_info: "Brand- und Explosionsgefährdung", form_value: 0}, {form_field: "5.1", form_field_info: "Brennbare Feststoffe, Flüssigkeiten und Gase", form_value: 0}, {form_field: "5.2", form_field_info: "Explosionsfähige Atmosphäre", form_value: 0}, {form_field: "5.3", form_field_info: "Verbaute Explosivstoffe", form_value: 0},
        {form_field: "6", form_field_info: "Thermische Gefährdung", form_value: 0}, {form_field: "6.1", form_field_info: "Heiße Medien / Oberflächen", form_value: 0}, {form_field: "6.2", form_field_info: "Kalte Medien / Oberflächen", form_value: 0},
        {form_field: "7", form_field_info: "Gefährdung durch spezielle physikalische Einwirkungen", form_value: 0}, {form_field: "7.1", form_field_info: "Lärm", form_value: 0}, {form_field: "7.2", form_field_info: "Ultraschall, Infraschall", form_value: 0}, {form_field: "7.3", form_field_info: "Ganzkörpervibration", form_value: 0}, {form_field: "7.4", form_field_info: "Hand- Arm- Vibration", form_value: 0}, {form_field: "7.5", form_field_info: "Künstliche optische Strahlung (IR, UV, LASER)", form_value: 0}, {form_field: "7.6", form_field_info: "Ionisierende Strahlung (Teilchenstrahlung)", form_value: 0}, {form_field: "7.7", form_field_info: "Elektromagnetische Strahlung", form_value: 0}, {form_field: "7.8", form_field_info: "Unter- / Überdruck", form_value: 0},
        {form_field: "8", form_field_info: "Gefährdung durch Arbeitsumgebung", form_value: 0}, {form_field: "8.1", form_field_info: "Klima (Raumklima, Lüftung, Witterungseinflüsse, Temperatur, Sonnenlicht)", form_value: 0}, {form_field: "8.2", form_field_info: "Beleuchtung. Lichtverhältnisse, Erkennbarkeit von opt. Signalen / Bildschirmen", form_value: 0}, {form_field: "8.3", form_field_info: "Ersticken (Sauerstoffreduzierte Atmosphäre), Ertrinken", form_value: 0}, {form_field: "8.4", form_field_info: "Unzureichende Flucht- und Verkehrswege, unzureichende Sicherheits- und Gesundheitsschutzkennzeichnung", form_value: 0}, {form_field: "8.5", form_field_info: "Unzureichende Bewegungsfläche am Arbeitsplatz, ungünstige Anordnung des Arbeitsplatzes, unzureichende Pausen- und Sanitärräume", form_value: 0}, {form_field: "8.6", form_field_info: "Unzureichend gestaltete Mensch- Maschine- Schnittstelle", form_value: 0},
        {form_field: "9", form_field_info: "Physikalische Belastung/Arbeitsschwere", form_value: 0}, {form_field: "9.1", form_field_info: "Heben, Halten, Tragen", form_value: 0}, {form_field: "9.2", form_field_info: "Ziehen / Schieben", form_value: 0}, {form_field: "9.3", form_field_info: "Manuelle Arbeit mit geringen Körperkräften", form_value: 0}, {form_field: "9.4", form_field_info: "Zwangshaltung (erzwungene Körperhaltung)", form_value: 0}, {form_field: "9.5", form_field_info: "Steigen, Klettern", form_value: 0}, {form_field: "9.6", form_field_info: "Arbeiten mit erhöhten Kraftanstrengungen und/oder Krafteinwirkungen", form_value: 0},
        {form_field: "10", form_field_info: "Psychische Faktoren", form_value: 0}, {form_field: "10.1", form_field_info: "Ungenügend gestaltete Arbeitsaufgaben", form_value: 0}, {form_field: "10.2", form_field_info: "Ungenügend gestaltete Arbeitsorganisation", form_value: 0}, {form_field: "10.3", form_field_info: "Ungenügend gestaltete soziale Bedingungen", form_value: 0}, {form_field: "10.4", form_field_info: "Ungenügend gestalteter Arbeitsplatz & Arbeitsplatzumgebungsbedingungen", form_value: 0},
        {form_field: "11", form_field_info: "Arbeitsschutzorganisation und sonstige Gefährdung", form_value: 0}, {form_field: "11.1", form_field_info: "Persönliche Schutzausstattung", form_value: 0}, {form_field: "11.2", form_field_info: "Verantwortlichkeiten, Unterweisungen", form_value: 0}, {form_field: "11.3", form_field_info: "Verhalten in Notfällen, Erste Hilfe", form_value: 0}, {form_field: "11.4", form_field_info: "Arbeitsmedizinische- und Arbeitssicherheitsbetreuung", form_value: 0}, {form_field: "11.5", form_field_info: "Gefährdung durch Dritte", form_value: 0}, {form_field: "11.6", form_field_info: "Gefährdung durch Tiere", form_value: 0}, {form_field: "11.7", form_field_info: "Gefährdung durch pflanzliche Produkte", form_value: 0}
    ])
    const [dataStatus, setdataStatus] = useState(false)
    const [checkData, setcheckData] = useState(false)
    const [siteswap, setsiteswap] = useState(false)
    const [siteswaprequest, setsiteswaprequest] = useState("")
    //Variables
    const token = localStorage.getItem('token')

    const saveData = async() => {
        if (dataStatus === true){
            setloadStatus(true)
            try {
                await axios({
                  method: 'POST',
                  url: (Apiurl + "/postClicker?general_id=" + localStorage.getItem('selectedProject')),
                  data: clickerData,
                  headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                }).then((response) => {
                    console.log("Clicker gespeichert")
                    setLoadClickerData(!LoadClickerData)
                    if (siteswap){
                        navigate('/home/companies/projects' + siteswaprequest)
                    }
                    closeload()
                })
              }
              catch (error) {
                console.log("Clicker konnte nicht gespeichert werden")
                alert("Something went wrong!")
                closeload()
              }
        }
    }
    
    const arraysAreEqual = (A, B) => {
        for (let i = 0; i < A.length; i++) {
          if (JSON.stringify(A[i]) !== JSON.stringify(B[i])) {
            return false;
          }
        }
        return true;
      }
    
    useEffect(() => {
        if (arraysAreEqual(Previous, clickerData)){
            setdataStatus(false)
        }
        else{
            setdataStatus(true)
        }
       },[checkData])

    useEffect(() => {
        console.log("Load Clickerdata")
        const getData = async() => {
            try{
                await axios({
                    method: 'GET',
                    url: Apiurl + '/loadWholeForm?form_id=' + localStorage.getItem('selectedProject'),
                    headers: {Authorization: `Bearer ${token}`}
                }).then(response => {
                    const data = response.data.form
                    const ClickerList = [
                        data["1"], data["1.1"], data["1.2"], data["1.3"], data["1.4"], data["1.5"], data["1.6"],
                        data["2"], data["2.1"], data["2.2"], data["2.3"],
                        data["3"], data["3.1"], data["3.2"], data["3.3"], data["3.4"], data["3.5"], data["3.6"], data["3.7"],
                        data["4"], data["4.1"], data["4.2"],
                        data["5"], data["5.1"], data["5.2"], data["5.3"],
                        data["6"], data["6.1"], data["6.2"],
                        data["7"], data["7.1"], data["7.2"], data["7.3"], data["7.4"], data["7.5"], data["7.6"], data["7.7"], data["7.8"],
                        data["8"], data["8.1"], data["8.2"], data["8.3"], data["8.4"], data["8.5"], data["8.6"],
                        data["9"], data["9.1"], data["9.2"], data["9.3"], data["9.4"], data["9.5"], data["9.6"],
                        data["10"], data["10.1"], data["10.2"], data["10.3"], data["10.4"],
                        data["11"], data["11.1"], data["11.2"], data["11.3"], data["11.4"], data["11.5"], data["11.6"], data["11.7"],
                    ]
                    if (ClickerList[0] !== undefined){
                        setClickerData(ClickerList)
                        setPrevious(_.cloneDeep(ClickerList))
                    }
                    setdataStatus(false)
                    console.log("This is Clickerlist",ClickerList)
                })
            }
            catch(e){
                console.log("Was not able to load clickerdata.")
                console.log(clickerData, Previous)
            }
        }

        getData()

    }, [LoadClickerData])

    const checkClickerTopic = () => {
        let sum = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        for (let index = 0; index < clickerData.length; index++){
            if (index > 0 && index < 7){sum[0] = sum[0] + clickerData[index].form_value}
            else if(index > 7 && index < 11) {sum[1] = sum[1] + clickerData[index].form_value}
            else if(index > 11 && index < 19) {sum[2] = sum[2] + clickerData[index].form_value}
            else if(index > 19 && index < 22) {sum[3] = sum[3] + clickerData[index].form_value}
            else if(index > 22 && index < 26) {sum[4] = sum[4] + clickerData[index].form_value}
            else if(index > 26 && index < 29) {sum[5] = sum[5] + clickerData[index].form_value}
            else if(index > 29 && index < 38) {sum[6] = sum[6] + clickerData[index].form_value}
            else if(index > 38 && index < 45) {sum[7] = sum[7] + clickerData[index].form_value}
            else if(index > 45 && index < 52) {sum[8] = sum[8] + clickerData[index].form_value}
            else if(index > 52 && index < 57) {sum[9] = sum[9] + clickerData[index].form_value}
            else if(index > 57 && index < 65) {sum[10] = sum[10] + clickerData[index].form_value}
        }
        if (sum[0] > 0){clickerData[0].form_value = 1}
        else{clickerData[0].form_value = 0}
        if (sum[1] > 0){clickerData[7].form_value = 1}
        else{clickerData[7].form_value = 0}
        if (sum[2] > 0){clickerData[11].form_value = 1}
        else{clickerData[11].form_value = 0}
        if (sum[3] > 0){clickerData[19].form_value = 1}
        else{clickerData[19].form_value = 0}
        if (sum[4] > 0){clickerData[22].form_value = 1}
        else{clickerData[22].form_value = 0}
        if (sum[5] > 0){clickerData[26].form_value = 1}
        else{clickerData[26].form_value = 0}
        if (sum[6] > 0){clickerData[29].form_value = 1}
        else{clickerData[29].form_value = 0}
        if (sum[7] > 0){clickerData[38].form_value = 1}
        else{clickerData[38].form_value = 0}
        if (sum[8] > 0){clickerData[45].form_value = 1}
        else{clickerData[45].form_value = 0}
        if (sum[9] > 0){clickerData[52].form_value = 1}
        else{clickerData[52].form_value = 0}
        if (sum[10] > 0){clickerData[57].form_value = 1}
        else{clickerData[57].form_value = 0}
    }

    const changeDecision = (e) => {
        const updatedClicker = [...clickerData]
        const index = updatedClicker.findIndex(item => item.form_field === e.target.id)
        if (e.target.name==="yes" && updatedClicker[index].form_value===0){
            updatedClicker[index].form_value = 1
            setClickerData(updatedClicker)
            checkClickerTopic(index)
        }
        else if (e.target.name==="no" && updatedClicker[index].form_value===1){
            updatedClicker[index].form_value = 0
            setClickerData(updatedClicker)
            checkClickerTopic()
        }
        else{
            console.log("Clicker has not been changed")
        }
        setcheckData(!checkData)
    }
    const getbox = (clicker) => {
        const isInteger = Number.isInteger(parseFloat(clicker.form_field));
        if (isInteger){
            return(
                <div key={clicker.form_field} className={clickerCSS.headerelementbox}>
                    <div className={clickerCSS.headertext}>
                        <p className={clickerCSS.textfield_number}>{clicker.form_field}</p>
                        <p>{clicker.form_field_info}</p>
                        <p></p>
                    </div>
                </div>
            )
        }
        else if (clicker.form_value===0){
            return(
                <div key={clicker.form_field} className={clickerCSS.elementbox}>
                    <div className={clickerCSS.textfield}>
                        <p className={clickerCSS.textfield_number}>{clicker.form_field}</p>
                        <p>{clicker.form_field_info}</p>
                        <p></p>
                    </div>
                    <div className={clickerCSS.decision}>
                        <button id={clicker.form_field} name='yes' className={clickerCSS.decisionbtnfalse} onClick={changeDecision}>Ja</button>
                        <button id={clicker.form_field} name='no' className={clickerCSS.decisionbtntrue} onClick={changeDecision}>Nein</button>
                    </div>
                </div>
            )
        }
        return(
            <div key={clicker.form_field} className={clickerCSS.elementbox}>
                    <div className={clickerCSS.textfield}>
                        <p className={clickerCSS.textfield_number}>{clicker.form_field}</p>
                        <p> {clicker.form_field_info}</p>
                        <p></p>
                    </div>
                    <div className={clickerCSS.decision}>
                        <button id={clicker.form_field} name='yes' className={clickerCSS.decisionbtntrue} onClick={changeDecision}>Ja</button>
                        <button id={clicker.form_field} name='no' className={clickerCSS.decisionbtnfalse} onClick={changeDecision}>Nein</button>
                    </div>
            </div>
        )
    }
    return(
        <div>
            <Loadingscreen loadStatus={loadStatus}/>
            <Editbar setsiteswap={setsiteswap} selectedHover ="clicker" saveData={saveData} dataStatus={dataStatus} setsiteswaprequest={setsiteswaprequest}/>
            <div>
                <Askforsave siteswap={siteswap} setsiteswap={setsiteswap} saveData={saveData} dataStatus={dataStatus} siteswaprequest={siteswaprequest} setsiteswaprequest={setsiteswaprequest}/>
            </div>
            <div className={clickerCSS.mainbox}>
                {clickerData.map(clicker => (
                    getbox(clicker)
                ))}
            </div>
        </div>
    )
}