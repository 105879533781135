import logo from '../../assets/Logo_mit_Standorte.png'
import navbarCSS from './navbar.module.css'
import {useEffect, useRef, useState} from "react"
import {FaBars, FaTimes} from "react-icons/fa"
import {useNavigate} from 'react-router-dom'
import {Passwordchange} from "../../components"


//https://stackoverflow.com/questions/24944925/background-image-jumps-when-address-bar-hides-ios-android-mobile-chrome
export default function Navbar(props){
    //Navigation
    const navigate = useNavigate();

    //References
    const navRef = useRef();
    const btnRef = useRef();
    const aref1 = useRef();
    const aref2 = useRef();
    const aref3 = useRef();
    const aref4 = useRef();

    //others
    const [changepassword, setchangepassword] = useState(false)

    //content
    const changeContent = (e) => {
        navRef.current.classList.toggle(navbarCSS.responsive_nav, false)
        btnRef.current.classList.toggle(navbarCSS.responsive_btn, false)
        navigate(e.target.name)
    }

    const logout = (e) => {
        navRef.current.classList.toggle(navbarCSS.responsive_nav, false)
        btnRef.current.classList.toggle(navbarCSS.responsive_btn, false)
        localStorage.clear()
        navigate('/')
    }

    //delay function for navbar
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
      

    useEffect(() => {
        //closeNavbar
        let showNavbar = async (e) => {
            if (navRef.current.contains(e.target) && !aref1.current.contains(e.target) && !aref2.current.contains(e.target) && !aref3.current.contains(e.target) && !aref4.current.contains(e.target)){
                navRef.current.classList.toggle(navbarCSS.responsive_nav, false)
                btnRef.current.classList.toggle(navbarCSS.responsive_btn, false)
                await delay(1000);
                navRef.current.classList.toggle(navbarCSS.secondnav, false)
            }
        };
        document.addEventListener("mousedown", showNavbar)

        return() =>{
            document.removeEventListener("mousedown", showNavbar)
        }
    });

    return (
        <div>
            <Passwordchange changepassword={changepassword} setchangepassword={setchangepassword}/>
            <header>
                <div className={navbarCSS.logoarea}>
                    <button className={navbarCSS.homebtn} name='/home/companies' onClick={changeContent}><img className={navbarCSS.logo} src={logo} alt="Logo_mit_Standorte.png" /></button>
                </div>
                <div>
                    <p className={navbarCSS.currentpage}>{props.currentpage}</p>
                </div>
                <div className={navbarCSS.nav} ref={navRef}>
                    <button name="/home/companies" className={navbarCSS.navbutton} ref={aref1} onClick={changeContent} >Firmen</button>
                    {/* <button name="/home/status" className={navbarCSS.navbutton} ref={aref2} onClick={changeContent}>Status</button>
                    <button name="contact" className={navbarCSS.navbutton} ref={aref3} onClick={changeContent}>Kontakt</button>
                    <button name="settings" className={navbarCSS.navbutton} ref={aref4} onClick={changeContent}>Einstellungen</button> */}
                    <button name="account" className={navbarCSS.navbutton} ref={aref4} onClick={(e) => setchangepassword(true)}>Account</button>
                    <button name="logout" className={navbarCSS.navbutton} ref={aref4} onClick={logout}>Logout</button>
                    <button className={navbarCSS.navclosebtn} onClick={()=>{navRef.current.classList.toggle(navbarCSS.responsive_nav, false); btnRef.current.classList.toggle(navbarCSS.responsive_btn, false)}}>
                        <FaTimes/>
                    </button>
                </div>
                <button className={navbarCSS.navbtn} ref={btnRef} onClick={()=>{navRef.current.classList.toggle(navbarCSS.responsive_nav, true); navRef.current.classList.toggle(navbarCSS.secondnav, true); btnRef.current.classList.toggle(navbarCSS.responsive_btn)}}>
                    <FaBars/>
                </button>
                <div>
                </div>
            </header>
        </div>
)
}