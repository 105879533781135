import pdfCSS from './pdf.module.css'
import Editbar from '../editbar'
import { useRef } from 'react'
import axios from 'axios';
import {Apiurl} from '../../../components/constants/constants'

export default function PDF(){
    const token = localStorage.getItem('token')
    // const [pdfUrl, setpdfUrl] = useState("")
    const pdfRef = useRef(false)
    const pdfbtnRef = useRef(false)
    const dataStatus = false
    // useEffect(() => {
    //     const getpdf = async() => {
    //         console.log("PDF download started!")
    //         try{
    //           const response = await axios ({
    //             method: 'GET',
    //             url: (Apiurl + "/downloadPDF?form_id=" + localStorage.getItem('selectedProject')),
    //             responseType: 'blob',
    //             headers: {Authorization: `Bearer ${token}`},
    //           }).then((response) => {
    //             setpdfUrl(URL.createObjectURL(response.data))
    //           })

    //         }
    //         catch (error) {
    //         }
    //         console.log("pdf download finished")
    //       }
    //     getpdf()
    // });

    const getpdf = async() => {
      pdfRef.current.classList.toggle(pdfCSS.load, true)
      pdfbtnRef.current.classList.toggle(pdfCSS.load, true)
      console.log("PDF download started!")
      try{
        await axios ({
          method: 'GET',
          url: (Apiurl + "/downloadPDF?form_id=" + localStorage.getItem('selectedProject')),
          responseType: 'blob',
          headers: {Authorization: `Bearer ${token}`},
        }).then((response) => {
          const pdfUrl = (URL.createObjectURL(response.data))
          window.open(pdfUrl, '_blank');
          pdfRef.current.classList.toggle(pdfCSS.load, false)
          pdfbtnRef.current.classList.toggle(pdfCSS.load, false)
        })

      }
      catch (error) {
        pdfRef.current.classList.toggle(pdfCSS.load, false)
        pdfbtnRef.current.classList.toggle(pdfCSS.load, false)
      }
      console.log("pdf download finished")
    }
  // const downloadpdf = () => {
  //   window.open(pdfUrl, '_blank');
  // }

    return(
        <div ref={pdfRef}>
            <Editbar selectedHover = "pdf" dataStatus={dataStatus}/>
            <div className={pdfCSS.mainbox}>
              <button ref={pdfbtnRef} className={pdfCSS.downloadbtn} onClick={getpdf}>download PDF</button>
            </div>
        </div>
    )
}