import settingsCSS from './settings.module.css'
import Editbar from '../editbar'

export default function Projectsettings(){

    return(
        <div>
            <Editbar selectedHover = "settings"/>
        </div>
    )
}